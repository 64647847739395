import React, { useState } from 'react';

import { Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { Button, CustomIconButton } from '../../common/button';

const ConfirmDialog = ({
                           isLoading, title, maxWidth, cancelText, secondaryAction, secondaryText, primaryAction,
                           primaryText, buttonText, displayIcon, iconTitle, icon
                       }) => {
    const [open, setOpen] = useState(false);

    const onClose = () => setOpen(false);

    return (
        <>
            {
                displayIcon
                    ? <CustomIconButton
                        title={ iconTitle }
                        isLoading={ isLoading }
                        onClick={ () => setOpen(true) }
                        icon={ icon }/>
                    : <Button isLoading={ isLoading } onClick={ () => setOpen(true) }>{ buttonText }</Button>
            }
            <Dialog open={ open } onClose={ onClose } fullWidth maxWidth={ maxWidth || 'xs' }>
                <DialogTitle>{ title }</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Grid container justifyContent="flex-end">
                        <DialogActions sx={ { gap: 2 } }>
                            {
                                cancelText &&
                                <Button isLoading={ isLoading }
                                        onClick={ () => {
                                            onClose();
                                        } }>
                                    { cancelText }
                                </Button>
                            }
                            {
                                secondaryText &&
                                <Button isLoading={ isLoading }
                                        onClick={ () => {
                                            secondaryAction();
                                            onClose();
                                        } }>
                                    { secondaryText }
                                </Button>
                            }
                            <Button isLoading={ isLoading }
                                    onClick={ () => {
                                        primaryAction();
                                        onClose();
                                    } }>
                                { primaryText }
                            </Button>
                        </DialogActions>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ConfirmDialog;
