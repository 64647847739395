import React, { useState } from 'react';

import { Grid, TextField } from '@mui/material';
import _ from 'lodash';
import deepmerge from 'deepmerge';

import SelectField from '../../common/select_field';
import { getCustomerFilterOptions, getCustomerStatusOptions } from '../../common/utils/customer_utils';

const CustomerFilters = ({ queryParams, setQueryParams }) => {
    const [queryFilter, setQueryFilter] = useState(queryParams?.queryFilter || 'none');
    const [customerStatus, setCustomerStatus] = useState(queryParams?.status || 'ALL');

    const onSearchInputChangeDebounced = _.debounce((value) => onSearchInputChange(value), 600);

    const onSearchInputChange = (query) => {
        setQueryParams(prevState => deepmerge(prevState, { queryValue: query }));
    };

    const onSelectQueryFilterChange = (event) => {
        setQueryFilter(event.target.value);

        if (event.target.value === 'none') {
            setQueryParams(prevState => deepmerge(prevState, { queryFilter: '', queryValue: '' }));
        } else {
            setQueryParams(prevState => deepmerge(prevState, { queryFilter: event.target.value }));
        }
    };

    const onSelectCustomerStatusChange = (event) => {
        setCustomerStatus(event.target.value);
        setQueryParams(prevState => deepmerge(prevState, { status: event.target.value }));
    };

    return (
        <Grid container gap={ 2 }>
            <Grid item xs={ 2 }>
                <SelectField
                    label="Filtr"
                    value={ queryFilter }
                    onChange={ onSelectQueryFilterChange }
                    options={ [{ value: 'none', label: 'Nie wybrano' }, ...getCustomerFilterOptions] }
                />
            </Grid>
            {
                queryFilter !== 'none' &&
                <Grid item xs={ 4 }>
                    <TextField
                        label="Szukaj"
                        helperText="Pole nie może być puste"
                        fullWidth
                        placeholder={ queryParams?.queryValue }
                        onChange={ event => onSearchInputChangeDebounced(event.target.value) }
                        InputLabelProps={ queryParams?.queryValue ? { shrink: true } : undefined }
                    />
                </Grid>
            }
            <Grid item>
                <SelectField
                    label="Status"
                    value={ customerStatus }
                    onChange={ onSelectCustomerStatusChange }
                    options={ [{ value: 'ALL', label: 'Wszystkie' }, ...getCustomerStatusOptions] }
                />
            </Grid>
        </Grid>
    );
};
export default CustomerFilters;
