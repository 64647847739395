import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { INCORRECT_EMAILS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const createIncorrectEmailSmsAction = createAsyncThunk(
    'customer/createIncorrectEmailSmsAction',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.post(`${ INCORRECT_EMAILS_PATH }/${ id }/send-sms`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const createIncorrectEmailSmsSlice = createSlice({
    name: 'createIncorrectEmailSmsSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(createIncorrectEmailSmsAction.pending, () => STATE__API__STARTED);
        builder.addCase(createIncorrectEmailSmsAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(createIncorrectEmailSmsAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const createIncorrectEmailSmsState = (state) => state.customer.createIncorrectEmailSms;
export default createIncorrectEmailSmsSlice.reducer;