import { TheftRegistryStatusEnum } from '../../../core/enums/order/theft_registry';

export const getTheftRegistryStatus = (status) => {
    switch (status) {
        case TheftRegistryStatusEnum.IN_REALIZATION.value:
            return TheftRegistryStatusEnum.IN_REALIZATION.label;
        case TheftRegistryStatusEnum.FINISHED.value:
            return TheftRegistryStatusEnum.FINISHED.label;
        default:
            return TheftRegistryStatusEnum.UNKNOWN.label;
    }
};

export const getTheftRegistryOptions = [
    { value: TheftRegistryStatusEnum.IN_REALIZATION.value, label: TheftRegistryStatusEnum.IN_REALIZATION.label },
    { value: TheftRegistryStatusEnum.FINISHED.value, label: TheftRegistryStatusEnum.FINISHED.label }
];
