import { deepFreeze } from '../../utils/misc_utils';

export const CustomerActivityActionEnum = deepFreeze({
    CREATE: {
        value: 'CREATE',
        label: 'Tworzenie konta'
    },
    LOGIN: {
        value: 'LOGIN',
        label: 'Logowanie'
    },
    LOGOUT: {
        value: 'LOGOUT',
        label: 'Wylogowanie'
    },
    ACTIVATE: {
        value: 'ACTIVATE',
        label: 'Aktywacja konta'
    },
    IDLE: {
        value: 'IDLE',
        label: 'Przejście w bezczynność'
    },
    TO_REMOVE: {
        value: 'TO_REMOVE',
        label: 'Zgłoszony do usunięcia'
    },
    REMOVE: {
        value: 'REMOVE',
        label: 'Usunięcie'
    },
    BLOCK: {
        value: 'BLOCK',
        label: 'Blokada konta'
    },
    UNBLOCK: {
        value: 'UNBLOCK',
        label: 'Odblokowanie konta'
    },
    REACTIVATE: {
        value: 'REACTIVATE',
        label: 'Automatyczna reaktywacja'
    },
    EMAIL_FAILURE: {
        value: 'EMAIL_FAILURE',
        label: 'Nieprawidłowy email'
    },
    EMAIL_FAILURE_SMS: {
        value: 'EMAIL_FAILURE_SMS',
        label: 'Nieprawidłowy email - wiadomość sms'
    },
    EMAIL_CHANGE: {
        value: 'EMAIL_CHANGE',
        label: 'Zmiana adresu email'
    },
});