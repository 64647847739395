import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { POD_RESERVATION_CONFIG_PATH } from '../../core/constants';
import { buildQueryParamValueArray, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getPodReservationConfigsAction = createAsyncThunk(
    'reservation/getPodReservationConfigsAction',
    async (podIds, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ POD_RESERVATION_CONFIG_PATH }?podId=${ buildQueryParamValueArray(podIds) }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getPodReservationConfigsSlice = createSlice({
    name: 'getPodReservationConfigsSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getPodReservationConfigsAction.pending, () => STATE__API__STARTED);
        builder.addCase(getPodReservationConfigsAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getPodReservationConfigsAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getPodReservationConfigsState = (state) => state.reservation.getPodReservationConfigs;
export default getPodReservationConfigsSlice.reducer;
