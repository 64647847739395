import React from 'react';

import { Grid, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';

const ThreeLevelSteps = ({ firstStep, secondStep, thirdStep }) => (
    <Grid container mb={ 4 } gap={ 2 }>
        <Typography fontWeight={ firstStep && 'bold' }>
            1 krok
        </Typography>
        <EastIcon/>
        <Typography fontWeight={ secondStep && 'bold' }>
            2 krok
        </Typography>
        <EastIcon/>
        <Typography fontWeight={ thirdStep && 'bold' }>
            3 krok
        </Typography>
    </Grid>
);

export const TwoLevelSteps = ({ firstStep, secondStep }) => (
    <Grid container mb={ 4 } gap={ 2 }>
        <Typography fontWeight={ firstStep && 'bold' }>
            1 krok
        </Typography>
        <EastIcon/>
        <Typography fontWeight={ secondStep && 'bold' }>
            2 krok
        </Typography>
    </Grid>
);

export default ThreeLevelSteps;
