import React, { useState } from 'react';

import { Grid, Tab, Tabs } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';

import Calendar from './lists/calendar';
import Reservation from './lists/reservation';
import Settings from "./settings";

const ReservationsPage = () => {
    const [tab, setTab] = useState(0);

    const renderView = () => {
        if (tab === 0) {
            return <Calendar/>;
        }
        if (tab === 1) {
            return <Reservation/>
        }

        return <Settings/>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 }>
                <Tabs
                    value={ tab }
                    onChange={ (_, value) => setTab(value) }
                    variant="scrollable"
                    scrollButtons
                    sx={ {
                        [`& .${ tabsClasses.scrollButtons }`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    } }
                >
                    <Tab label="Kalendarz produktów pod rezerwacje"/>
                    <Tab label="Rezerwacje użytkowników"/>
                    <Tab label="Ustawienia"/>
                </Tabs>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default ReservationsPage;