import { CustomerFilterEnum, CustomerStatusEnum } from '../../../core/enums/customer/customer';
import { CustomerActivityActionEnum } from '../../../core/enums/customer/customer_activity';

export const getCustomerFilterOptions = [
    { value: CustomerFilterEnum.ID.value, label: CustomerFilterEnum.ID.label },
    { value: CustomerFilterEnum.SURNAME.value, label: CustomerFilterEnum.SURNAME.label },
    { value: CustomerFilterEnum.PHONE_NUMBER.value, label: CustomerFilterEnum.PHONE_NUMBER.label },
    { value: CustomerFilterEnum.EMAIL.value, label: CustomerFilterEnum.EMAIL.label }
];

export const getCustomerStatusOptions = [
    { value: CustomerStatusEnum.TO_ACTIVATE.value, label: CustomerStatusEnum.TO_ACTIVATE.label },
    { value: CustomerStatusEnum.ACTIVE.value, label: CustomerStatusEnum.ACTIVE.label },
    { value: CustomerStatusEnum.INACTIVE.value, label: CustomerStatusEnum.INACTIVE.label },
    { value: CustomerStatusEnum.TO_REMOVE.value, label: CustomerStatusEnum.TO_REMOVE.label }
];

export const getCustomerStatus = (status) => {
    switch (status) {
        case CustomerStatusEnum.TO_ACTIVATE.value:
            return CustomerStatusEnum.TO_ACTIVATE.label;
        case CustomerStatusEnum.ACTIVE.value:
            return CustomerStatusEnum.ACTIVE.label;
        case CustomerStatusEnum.INACTIVE.value:
            return CustomerStatusEnum.INACTIVE.label;
        case CustomerStatusEnum.TO_REMOVE.value:
            return CustomerStatusEnum.TO_REMOVE.label;
        case CustomerStatusEnum.REMOVED.value:
            return CustomerStatusEnum.REMOVED.label;
        case CustomerStatusEnum.BLOCKED.value:
            return CustomerStatusEnum.BLOCKED.label;
        default:
            return CustomerStatusEnum.UNKNOWN.label;
    }
};

export const getCustomerActivityAction = (action) => {
    switch (action) {
        case CustomerActivityActionEnum.CREATE.value:
            return CustomerActivityActionEnum.CREATE.label;
        case CustomerActivityActionEnum.LOGIN.value:
            return CustomerActivityActionEnum.LOGIN.label;
        case CustomerActivityActionEnum.LOGOUT.value:
            return CustomerActivityActionEnum.LOGOUT.label;
        case CustomerActivityActionEnum.ACTIVATE.value:
            return CustomerActivityActionEnum.ACTIVATE.label;
        case CustomerActivityActionEnum.IDLE.value:
            return CustomerActivityActionEnum.IDLE.label;
        case CustomerActivityActionEnum.TO_REMOVE.value:
            return CustomerActivityActionEnum.TO_REMOVE.label;
        case CustomerActivityActionEnum.REMOVE.value:
            return CustomerActivityActionEnum.REMOVE.label;
        case CustomerActivityActionEnum.BLOCK.value:
            return CustomerActivityActionEnum.BLOCK.label;
        case CustomerActivityActionEnum.REACTIVATE.value:
            return CustomerActivityActionEnum.REACTIVATE.label;
        case CustomerActivityActionEnum.EMAIL_FAILURE.value:
            return CustomerActivityActionEnum.EMAIL_FAILURE.label;
        case CustomerActivityActionEnum.EMAIL_FAILURE_SMS.value:
            return CustomerActivityActionEnum.EMAIL_FAILURE_SMS.label;
        case CustomerActivityActionEnum.EMAIL_CHANGE.value:
            return CustomerActivityActionEnum.EMAIL_CHANGE.label;
        default:
            return CustomerActivityActionEnum.UNBLOCK.label;
    }
};

export const getButtonText = (status) => {
    switch (status) {
        case CustomerStatusEnum.BLOCKED.value:
            return 'Odblokuj';
        default:
            return 'Zablokuj';
    }
};

export const getUpdateCustomerStatus = (status) => {
    switch (status) {
        case CustomerStatusEnum.BLOCKED.value:
            return CustomerStatusEnum.ACTIVE.value;
        default:
            return CustomerStatusEnum.BLOCKED.value;
    }
};
