import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';

import useNotification from '../../../../../../core/hooks/use_notification';
import ThreeLevelSteps from '../../../../common/steps';
import SummaryButtons from './summary_buttons';
import { createFoodPartnerOrderAction, createFoodPartnerOrderState } from '../../../../../../features/inventory/create_food_partner_order';
import { createAndSendFoodPartnerOrderAction, createAndSendFoodPartnerOrderState } from '../../../../../../features/inventory/create_and_send_food_partner_order';
import { createFoodPartnerOrderNotificationData } from '../../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../../core/enums/common/api';
import { ROUTE_FOOD_PARTNER_ORDERS } from '../../../../../../core/constants';
import SummaryList from '../../../../common/warehouse_and_food_partner_order_form/summary_list';
import _ from 'lodash';
import { InventoryServerErrorMessageEnum } from '../../../../../../core/enums/inventory/message';

const Summary = ({ setView, data, setErrors }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showNotification } = useNotification();

    const createFoodPartnerOrder = useSelector(createFoodPartnerOrderState);
    const createAndSendFoodPartnerOrder = useSelector(createAndSendFoodPartnerOrderState);

    const onReturn = () => {
        data?.form?.locationDivision
            ? setView(2)
            : setView(1);
    };

    const onSave = (generateLabels) => {
        const form = { ..._.omit(data?.form, ['attachments']), generateLabels };
        const attachments = data?.form?.attachments;

        onSubmit(createFoodPartnerOrderAction, { form, attachments });
    };

    const onSaveAndSend = (generateLabels) => {
        const form = { ..._.omit(data?.form, ['attachments']), generateLabels };
        const attachments = data?.form?.attachments;

        onSubmit(createAndSendFoodPartnerOrderAction, { form, attachments });
    };

    const onSubmit = (action, form) => {
        dispatch(action(form))
            .then(response => {
                showNotification(createFoodPartnerOrderNotificationData(response));

                if (response?.payload?.message?.substring(0, response?.payload?.message?.indexOf(' ')) === InventoryServerErrorMessageEnum.PRODUCT_PRICE_MISMATCH.errorCode) {
                    const errors = { products: [] };
                    const productId = response?.payload?.productId;

                    data?.formik?.destinationsList.forEach((_, index) => {
                        data?.formik?.productsList[index].forEach((product, productIndex) => {
                            if (product?.productId === productId) {
                                const oldValues = errors.products[index];
                                errors.products[index] = {
                                    ...oldValues,
                                    [productIndex]: {
                                        ...errors.products?.[index]?.[productIndex],
                                        price: 'Niejednolita cena'
                                    }
                                };
                            }
                        });
                    });

                    setErrors(errors);
                }

                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && navigate(ROUTE_FOOD_PARTNER_ORDERS);
            });
    };

    const getSummaryList = () => {
        const locationDivision = data?.form?.locationDivision;
        const allProducts = data?.formik?.productsList.flat();
        const types = [...new Set(allProducts?.map(product => product?.type))];
        const productsByType = types?.map(type => [...allProducts?.filter(product => product?.type === type)]);
        const products = productsByType?.map((products, index) => {
            return [...new Set(productsByType[index]?.map(product => product?.name))]?.map(productName => {
                const productQuantity = products?.filter(product => product?.name === productName)
                    ?.map(product => product?.quantity)
                    ?.reduce((accumulator, current) => accumulator + current, 0);

                return {
                    name: productName,
                    quantity: productQuantity
                };
            });
        });
        const destinationProducts = types?.map(type => {
            return data?.formik?.destinationsList?.map((_, index) => {
                const productsByType = data?.formik?.productsList[index]?.filter(product => product?.type === type);

                return [...new Set(productsByType?.map(product => product?.name))]?.map(productName => {
                    const productQuantity = productsByType?.filter(product => product?.name === productName)
                        ?.map(product => product?.quantity)
                        ?.reduce((accumulator, current) => accumulator + current, 0);

                    return {
                        name: productName,
                        quantity: productQuantity
                    };
                });
            });
        });

        return (
            <SummaryList
                showDestinationProductsModal={ locationDivision }
                types={ types }
                productsByType={ productsByType }
                products={ products }
                destinationsList={ data?.formik?.destinationsList }
                destinationProducts={ destinationProducts }
            />
        );
    };

    return (
        <Grid container rowGap={ 2 } paddingTop={ 2 }>
            <ThreeLevelSteps thirdStep/>
            <Grid container>
                <Typography variant="h4">
                    Podsumowanie
                </Typography>
            </Grid>
            <Grid container rowGap={ 2 }>
                {
                    getSummaryList()
                }
            </Grid>
            <SummaryButtons
                onReturn={ onReturn }
                isLoading={ createFoodPartnerOrder?.loading || createAndSendFoodPartnerOrder?.loading }
                onSave={ (generateLabels) => onSave(generateLabels) }
                onSaveAndSend={ (generateLabels) => onSaveAndSend(generateLabels) }
            />
        </Grid>
    );
};

export default Summary;
