import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, LinearProgress } from '@mui/material';

import GeneralDataForm from './general_data_form';
import ProductsListForm from './products_list_form';
import { getAllWarehousesNotificationData } from '../../../../../../core/utils/notification_utils';
import { getAllWarehousesAction, getAllWarehousesState } from '../../../../../../features/inventory/get_all_warehouses';
import useNotification from '../../../../../../core/hooks/use_notification';
import { WarehouseTypeEnum } from '../../../../../../core/enums/inventory/warehouse';

const TheftRegistryForm = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const { customerId } = useParams();

    const getAllWarehouses = useSelector(getAllWarehousesState);

    const [view, setView] = useState(0);
    const [form, setForm] = useState({});

    useEffect(() => {
        dispatch(getAllWarehousesAction())
            .then(response => showErrorMessage(getAllWarehousesNotificationData(response)));
    }, [dispatch, showErrorMessage]);

    const onSubmitGeneralData = (values) => {
        setForm(values);
        setView(1);
    };

    const renderView = () => {
        if (getAllWarehouses?.loading) {
            return (
                <Grid item width={ '100%' }>
                    <LinearProgress/>
                </Grid>
            );
        }

        if (getAllWarehouses?.data) {
            if (view === 1) {
                return (
                    <ProductsListForm
                        customerId={customerId}
                        form={ form }
                        setView={ setView }
                        inventoryWarehouseId={ getAllWarehouses?.data?.find(warehouse => warehouse?.type === WarehouseTypeEnum.INVENTORY.value)?.internalId }/>
                );
            } else {
                return (
                    <GeneralDataForm
                        customerId={ customerId }
                        form={ form }
                        onSubmitGeneralData={ onSubmitGeneralData }/>
                );
            }
        }

        return <></>;
    };

    return (
        <Grid container mb={ 6 } pl={ 5 } pr={ 5 }>
            {
                renderView()
            }
        </Grid>
    );
};

export default TheftRegistryForm;
