import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import deepmerge from 'deepmerge';
import { Box, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';

import useNotification from '../../../../core/hooks/use_notification';
import { getDefaultTheftRegistriesPaginationQueryValues } from '../../../../core/utils/api_utils';
import { getCustomerNotificationData, getPodsNotificationData, getTheftRegistriesNotificationData, updateCustomerStatusNotificationData, updateTheftRegistryStatusNotificationData } from '../../../../core/utils/notification_utils';
import PaginationControls from '../../../common/pagination_controls';
import { getTheftRegistriesAction, getTheftRegistriesState } from '../../../../features/order/get_theft_registries';
import { getAllPodsAction, getAllPodsState } from '../../../../features/pod/get_all_pods';
import { getTheftRegistryStatus } from '../../../common/utils/theft_registry_utils';
import { Button, SubmitButton } from '../../../common/button';
import { GET_CUSTOMER_THEFT_REGISTRY, GET_ROUTE_CUSTOMER_THEFT_REGISTRY_NEW, MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT } from '../../../../core/constants';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { updateTheftRegistryStatusAction, updateTheftRegistryStatusState } from '../../../../features/order/update_theft_registry_status';
import { TheftRegistryStatusEnum } from '../../../../core/enums/order/theft_registry';
import { updateCustomerStatusAction, updateCustomerStatusState } from '../../../../features/customer/update_customer_status';
import { getButtonText, getUpdateCustomerStatus } from '../../../common/utils/customer_utils';
import { getCustomerAction, getCustomerState } from '../../../../features/customer/get_customer';

const initialQueryParams = {
    ...getDefaultTheftRegistriesPaginationQueryValues
};

const TheftRegistriesList = ({ customerId, tab }) => {
    const dispatch = useDispatch();
    const { showNotification, showErrorMessage } = useNotification();
    const navigate = useNavigate();

    const [queryParams, setQueryParams] = useState({ ...initialQueryParams, customerId });

    const getTheftRegistries = useSelector(getTheftRegistriesState);
    const getAllPods = useSelector(getAllPodsState);
    const updateTheftRegistryStatus = useSelector(updateTheftRegistryStatusState);
    const updateCustomerStatus = useSelector(updateCustomerStatusState);
    const getCustomer = useSelector(getCustomerState);

    const refreshTheftRegistryList = useCallback(() => {
        dispatch(getTheftRegistriesAction(queryParams))
            .then(response => showErrorMessage(getTheftRegistriesNotificationData(response)));
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshTheftRegistryList();
    }, [refreshTheftRegistryList]);

    const onUpdateTheftRegistryStatus = (id) => {
        dispatch(updateTheftRegistryStatusAction({ id, status: TheftRegistryStatusEnum.FINISHED.value }))
            .then(response => {
                showNotification(updateTheftRegistryStatusNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshTheftRegistryList();
            });
    };

    const onUpdateCustomerStatus = (customer) => {
        dispatch(updateCustomerStatusAction({ customerId: customer?.id, status: getUpdateCustomerStatus(customer?.status) }))
            .then(response => {
                showNotification(updateCustomerStatusNotificationData(response));

                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    dispatch(getCustomerAction(customerId))
                        .then(response => showErrorMessage(getCustomerNotificationData(response)));
                }
            });
    };

    return (
        <Grid>
            <Grid container item mb={ 4 }>
                <Button onClick={ () => navigate(GET_ROUTE_CUSTOMER_THEFT_REGISTRY_NEW(customerId), { state: { tab } }) }>
                    Dodaj wpis do kartoteki
                </Button>
            </Grid>
            <Grid container item mb={ 4 }>
                <SubmitButton onClick={ () => onUpdateCustomerStatus(getCustomer?.data) } isLoading={ updateCustomerStatus?.loading || getCustomer?.loading }>
                    {
                        getButtonText(getCustomer?.data?.status)
                    }
                </SubmitButton>
            </Grid>
            {
                getTheftRegistries?.loading &&
                <LinearProgress/>
            }
            {
                getTheftRegistries?.data?.content &&
                <Grid container>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Data kradzieży</TableCell>
                                    <TableCell align="center">Pod</TableCell>
                                    <TableCell align="center">Wartość</TableCell>
                                    <TableCell align="center">Częściowa kradzież</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Zmiana statusu</TableCell>
                                    <TableCell align="center">Numer faktury</TableCell>
                                    <TableCell align="center">Podgląd</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getTheftRegistries?.data?.content?.map(theftRegistry => (
                                        <TableRow
                                            key={ theftRegistry?.id }
                                            hover
                                        >
                                            <TableCell>
                                                {
                                                    moment(theftRegistry?.theftDate, MISC_DATE_ISO_FORMAT)
                                                        .format(MISC_DATE_POLISH_FORMAT)
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    getAllPods?.data?.map(pod => ({ id: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') }))
                                                        .find(pod => pod?.id === theftRegistry?.podId)?.label || 'Brak'
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                { theftRegistry?.shopAdminTotalSellingPrice.toFixed(2) } { theftRegistry?.currencyShortcut }
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    theftRegistry?.partialTheft
                                                        ? <CheckIcon color="success"/>
                                                        : <ClearIcon color="error"/>
                                                }
                                            </TableCell>
                                            <TableCell align="center">{ getTheftRegistryStatus(theftRegistry?.status) }</TableCell>
                                            <TableCell align="center">
                                                {
                                                    theftRegistry?.status === TheftRegistryStatusEnum.IN_REALIZATION.value &&
                                                    <SubmitButton onClick={ () => onUpdateTheftRegistryStatus(theftRegistry?.id) } isLoading={ updateTheftRegistryStatus?.loading }>
                                                        Opłacono
                                                    </SubmitButton>
                                                }
                                            </TableCell>
                                            <TableCell align="center">{ theftRegistry?.invoiceNumber }</TableCell>
                                            <TableCell align="center">
                                                <SearchIcon onClick={ () => navigate(GET_CUSTOMER_THEFT_REGISTRY(customerId, theftRegistry?.id),
                                                    { state: { tab, incompleteOrderId: theftRegistry?.incompleteOrderId, theftDate: theftRegistry?.theftDate, podId: theftRegistry?.podId } }) } cursor="pointer"/>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getTheftRegistries?.data?.pageable?.pageNumber }
                            totalPages={ getTheftRegistries?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default TheftRegistriesList;
