import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Icon, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import deepmerge from 'deepmerge';

import useNotification from '../../../../core/hooks/use_notification';
import { getCartEventsNotificationData, getCustomerActivitiesNotificationData, getPodsNotificationData } from '../../../../core/utils/notification_utils';
import { getCustomerActivitiesAction, getCustomerActivitiesState } from '../../../../features/customer/get_customer_activities';
import { MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT } from '../../../../core/constants';
import PaginationControls from '../../../common/pagination_controls';
import { getDefaultCustomerActivitiesPaginationQueryValues } from '../../../../core/utils/api_utils';
import { getAllPodsAction, getAllPodsState } from '../../../../features/pod/get_all_pods';
import { CustomerActivityActionEnum } from '../../../../core/enums/customer/customer_activity';
import { getCartEventsAction, getCartEventsState, invalidateCartEvents } from '../../../../features/order/get_cart_events';
import { getCustomerActivityAction } from '../../../common/utils/customer_utils';
import { getCartEventAction } from '../../../common/utils/order_utils';

const initialQueryParams = {
    ...getDefaultCustomerActivitiesPaginationQueryValues
};

const ActivitiesList = ({ customerId }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const [queryParams, setQueryParams] = useState(initialQueryParams);
    const [expanded, setExpanded] = useState(undefined);

    const getAllPods = useSelector(getAllPodsState);
    const getCustomerActivities = useSelector(getCustomerActivitiesState);
    const getCartEvents = useSelector(getCartEventsState);

    useEffect(() => {
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
        dispatch(getCustomerActivitiesAction({ queryParams, id: customerId }))
            .then(response => showErrorMessage(getCustomerActivitiesNotificationData(response)));
    }, [customerId, dispatch, queryParams, showErrorMessage]);

    const handleChange = (panelId, date, nextLoginDate, action) => (_, isExpanded) => {
        dispatch(invalidateCartEvents());

        if (action !== CustomerActivityActionEnum.LOGIN.value) {
            return;
        }

        if (isExpanded) {
            setExpanded(panelId);
            dispatch(getCartEventsAction({ customerId, fromDate: date, toDate: nextLoginDate }))
                .then(response => showErrorMessage(getCartEventsNotificationData(response)));
        } else {
            setExpanded(undefined);
        }
    };

    return (
        <Grid rowGap={ 4 }>
            {
                (getCustomerActivities?.loading || getAllPods?.loading) &&
                <LinearProgress/>
            }
            {
                (getCustomerActivities?.data?.content && getAllPods?.data) &&
                <Grid container>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Punkt sprzedaży</TableCell>
                                    <TableCell align="left">Data</TableCell>
                                    <TableCell align="left">Akcja</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getCustomerActivities?.data?.content?.map(customerActivity => (
                                        <TableRow key={ customerActivity?.id }>
                                            <TableCell colSpan={ 3 } sx={ { padding: '0px 0px' } }>
                                                <Accordion
                                                    expanded={ expanded === customerActivity?.id }
                                                    onChange={ handleChange(customerActivity?.id, customerActivity?.date, customerActivity?.nextLoginDate, customerActivity?.action) }>
                                                    <AccordionSummary
                                                        expandIcon={ customerActivity?.action === CustomerActivityActionEnum.LOGIN.value ? <ExpandMoreIcon/> : <Icon/> }>
                                                        <Grid container justifyContent="space-between">
                                                            <Typography width="50%" sx={ { fontSize: '0.875rem !important' } }>
                                                                {
                                                                    getAllPods?.data?.map(pod => ({ id: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') }))
                                                                        .find(pod => pod?.id === customerActivity?.podId)?.label || 'Brak'
                                                                }
                                                            </Typography>
                                                            <Typography width="20%" sx={ { fontSize: '0.875rem !important' } }>
                                                                {
                                                                    moment(customerActivity?.date, MISC_DATE_ISO_FORMAT)
                                                                        .format(MISC_DATE_POLISH_FORMAT)
                                                                }
                                                            </Typography>
                                                            <Typography width="30%" sx={ { fontSize: '0.875rem !important' } }>
                                                                {
                                                                    getCustomerActivityAction(customerActivity?.action)
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    {
                                                        customerActivity?.action === CustomerActivityActionEnum.LOGIN.value &&
                                                        <AccordionDetails>
                                                            <Grid container justifyContent="space-between">
                                                                {
                                                                    getCartEvents?.loading &&
                                                                    <Grid item width={ '100%' }>
                                                                        <LinearProgress/>
                                                                    </Grid>
                                                                }
                                                                {
                                                                    getCartEvents?.data &&
                                                                    <Grid container>
                                                                        <TableContainer>
                                                                            <Table stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell>ID Koszyka</TableCell>
                                                                                        <TableCell align="center">Data</TableCell>
                                                                                        <TableCell align="center">Akcja</TableCell>
                                                                                        <TableCell align="center">Dodatkowe informacje</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {
                                                                                        getCartEvents?.data?.map(cartEvent => (
                                                                                            <TableRow
                                                                                                key={ cartEvent?.id }
                                                                                                hover>
                                                                                                <TableCell>
                                                                                                    {
                                                                                                        cartEvent?.cartId
                                                                                                    }
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    align="center">
                                                                                                    {
                                                                                                        moment(cartEvent?.date, MISC_DATE_ISO_FORMAT)
                                                                                                            .format(MISC_DATE_POLISH_FORMAT)
                                                                                                    }
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    align="center">
                                                                                                    {
                                                                                                        getCartEventAction(cartEvent?.action)
                                                                                                    }
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    align="center">
                                                                                                    {
                                                                                                        cartEvent?.additionalParams
                                                                                                    }
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        ))
                                                                                    }
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </AccordionDetails>
                                                    }
                                                </Accordion>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getCustomerActivities?.data?.pageable?.pageNumber }
                            totalPages={ getCustomerActivities?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default ActivitiesList;
