import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import { useEffect } from "react";

const EditToolbar = (props) => {
    const { setRows, setRowModesModel, columns } = props;

    const handleClick = () => {
        const id = randomId();
        const defaultObject = {};
        columns.forEach(column => { defaultObject[column.field] = column.defaultValue })

        setRows((oldRows) => [
            ...oldRows,
            { id, ...defaultObject, isNew: true },
        ]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={ <AddIcon /> } onClick={ handleClick }>
                Dodaj
            </Button>
        </GridToolbarContainer>
    );
}

export const EditableTable = ({ columns, initialRows, onChange }) => {
    const [rows, setRows] = React.useState(initialRows);
    const [rowModesModel, setRowModesModel] = React.useState({});

    useEffect(() => {
        onChange(rows);
    }, [rows, onChange])

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        setRows(rows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    return (
        <Box
            sx={{
                height: 300,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                rows={ rows }
                columns={ [...columns,
                    {
                        field: 'actions',
                        type: 'actions',
                        headerName: 'Akcje',
                        width: 100,
                        cellClassName: 'actions',
                        getActions: ({ id }) => {
                            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                            if (isInEditMode) {
                                return [
                                    <GridActionsCellItem
                                        icon={ <CheckIcon /> }
                                        label="Save"
                                        sx={{
                                            color: 'primary.main',
                                        }}
                                        onClick={ handleSaveClick(id) }
                                    />,
                                    <GridActionsCellItem
                                        icon={<CancelIcon />}
                                        label="Cancel"
                                        className="textPrimary"
                                        onClick={ handleCancelClick(id) }
                                        color="inherit"
                                    />,
                                ];
                            }

                            return [
                                <GridActionsCellItem
                                    icon={ <EditIcon /> }
                                    label="Edit"
                                    className="textPrimary"
                                    onClick={ handleEditClick(id) }
                                    color="inherit"
                                />,
                                <GridActionsCellItem
                                    icon={ <DeleteIcon /> }
                                    label="Delete"
                                    onClick={ handleDeleteClick(id) }
                                    color="inherit"
                                />,
                            ];
                        },
                    }] }
                editMode="row"
                rowModesModel={ rowModesModel }
                onRowModesModelChange={ handleRowModesModelChange }
                onRowEditStop={ handleRowEditStop }
                processRowUpdate={ processRowUpdate }
                hideFooter
                localeText={{ noRowsLabel: "Brak zdefiniowanych półek" }}
                slots={{ toolbar: EditToolbar }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel, columns },
                }}
            />
        </Box>
    );
}