import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment/moment';
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';

import { GET_CUSTOMER_THEFT_REGISTRY, MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT } from '../../../core/constants';
import { getTheftRegistryStatus } from '../../common/utils/theft_registry_utils';
import { TheftRegistryStatusEnum } from '../../../core/enums/order/theft_registry';
import { SubmitButton } from '../../common/button';
import { getAllPodsState } from '../../../features/pod/get_all_pods';
import { getTheftRegistriesForStoreState } from '../../../features/order/get_theft_registries_for_store';
import { updateTheftRegistryStatusAction, updateTheftRegistryStatusState } from '../../../features/order/update_theft_registry_status';
import { updateTheftRegistryStatusNotificationData } from '../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../core/enums/common/api';
import useNotification from '../../../core/hooks/use_notification';

const TheftRegistriesList = ({ queryParams, refreshList }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();
    const navigate = useNavigate();

    const getTheftRegistriesForStore = useSelector(getTheftRegistriesForStoreState);
    const getAllPods = useSelector(getAllPodsState);
    const updateTheftRegistryStatus = useSelector(updateTheftRegistryStatusState);

    const onUpdateTheftRegistryStatus = (id) => {
        dispatch(updateTheftRegistryStatusAction({ id, status: TheftRegistryStatusEnum.FINISHED.value }))
            .then(response => {
                showNotification(updateTheftRegistryStatusNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Data kradzieży</TableCell>
                        <TableCell align="center">Pod</TableCell>
                        <TableCell align="center">Wartość</TableCell>
                        <TableCell align="center">Częściowa kradzież</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Zmiana statusu</TableCell>
                        <TableCell align="center">Numer faktury</TableCell>
                        <TableCell align="center">Podgląd</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getTheftRegistriesForStore?.data?.content?.map(theftRegistry => (
                            <TableRow
                                key={ theftRegistry?.id }
                                hover
                            >
                                <TableCell>
                                    {
                                        moment(theftRegistry?.theftDate, MISC_DATE_ISO_FORMAT)
                                            .format(MISC_DATE_POLISH_FORMAT)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        getAllPods?.data?.map(pod => ({ id: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') }))
                                            .find(pod => pod?.id === theftRegistry?.podId)?.label || 'Brak'
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    { theftRegistry?.shopAdminTotalSellingPrice.toFixed(2) } { theftRegistry?.currencyShortcut }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        theftRegistry?.partialTheft
                                            ? <CheckIcon color="success"/>
                                            : <ClearIcon color="error"/>
                                    }
                                </TableCell>
                                <TableCell align="center">{ getTheftRegistryStatus(theftRegistry?.status) }</TableCell>
                                <TableCell align="center">
                                    {
                                        theftRegistry?.status === TheftRegistryStatusEnum.IN_REALIZATION.value &&
                                        <SubmitButton onClick={ () => onUpdateTheftRegistryStatus(theftRegistry?.id) } isLoading={ updateTheftRegistryStatus?.loading }>
                                            Opłacono
                                        </SubmitButton>
                                    }
                                </TableCell>
                                <TableCell align="center">{ theftRegistry?.invoiceNumber }</TableCell>
                                <TableCell align="center">
                                    <SearchIcon onClick={ () => navigate(GET_CUSTOMER_THEFT_REGISTRY(theftRegistry?.customerId, theftRegistry?.id),
                                        { state: { source: 'TheftRegistriesList', queryParams, incompleteOrderId: theftRegistry?.incompleteOrderId, theftDate: theftRegistry?.theftDate, podId: theftRegistry?.podId } }) }
                                                cursor="pointer"/>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TheftRegistriesList;
