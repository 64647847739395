import { combineReducers } from '@reduxjs/toolkit';

import getCustomers from './get_customers';
import getCustomer from './get_customer';
import getCustomersByIds from './get_customers_by_ids';
import getCustomersByPhoneNumber from './get_customers_by_phone_number';
import getCustomerActivities from './get_customer_activities';
import updateCustomerStatus from './update_customer_status';
import getIncorrectEmails from './get_incorrect_emails';
import createIncorrectEmail from './create_incorrect_email';
import createIncorrectEmailSms from './create_incorrect_email_sms';

export default combineReducers({
    getCustomers, getCustomer, getCustomersByIds, getCustomersByPhoneNumber, getCustomerActivities, updateCustomerStatus,
    getIncorrectEmails, createIncorrectEmail, createIncorrectEmailSms
});
