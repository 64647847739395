import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import _ from 'lodash';
import moment from 'moment/moment';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import { getPriceByDateNotificationData, getWarehouseProductBatchesNotificationData } from '../../../../../../core/utils/notification_utils';
import useNotification from '../../../../../../core/hooks/use_notification';
import { getWarehouseProductBatchesAction, getWarehouseProductBatchesState, invalidateProductBatches } from '../../../../../../features/inventory/get_warehouse_product_batches';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_DATE_ONLY_FORMAT } from '../../../../../../core/constants';
import { Button } from '../../../../../common/button';
import AutocompleteMultipleSearchOnType from '../../../../../common/autocomplete';
import { ApiRequestStatusEnum } from '../../../../../../core/enums/common/api';
import { getPriceByDateAction, getPriceByDateState } from '../../../../../../features/price/get_price_by_date';

const AddProductsDialog = ({ form, formik, customerId, sourceWarehouseId }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getWarehouseProductBatches = useSelector(getWarehouseProductBatchesState);
    const getPriceByDate = useSelector(getPriceByDateState);

    const quantityRef = useRef(null);

    const mapProductsOptions = useCallback((data) => {
        return data?.map(resource => ({
            key: resource?.product?.id + resource?.productBatchId,
            value: resource?.product?.id + '_' + resource?.productBatchId,
            label: resource?.product?.name,
            secondaryLabel: 'Data przydatności: ' + moment(resource?.expiryDate, MISC_DATE_ISO_DATE_ONLY_FORMAT).format(MISC_DATE_POLISH_DATE_ONLY_FORMAT) + ', max liczba szt.: ' + resource?.quantity,
            details: {
                name: resource?.product?.name,
                productId: resource?.product?.id,
                productBatchId: resource?.productBatchId,
                quantity: '',
                expirationDate: moment(resource?.expiryDate),
                type: resource?.product?.type
            },
            original: resource
        }));
    }, []);

    const [open, setOpen] = useState(false);
    const [productsOptions, setProductsOptions] = useState(mapProductsOptions(getWarehouseProductBatches?.data) || []);
    const [loadingProductsOptions, setLoadingProductsOptions] = useState(false);
    const [chosenProducts, setChosenProducts] = useState([]);
    const [chosenProductsWithPrices, setChosenProductsWithPrices] = useState([]);

    const onClose = () => {
        setOpen(false);
        dispatch(invalidateProductBatches());
        setChosenProducts([]);
        setProductsOptions([]);
    };

    useEffect(() => {
        const positions = formik?.values?.positions || [];

        chosenProductsWithPrices.forEach(product => {
            const lastItem = positions[positions.length - 1];
            const index = positions?.findIndex(p => (p?.productId === product?.productId) && (p?.productBatchId === product?.productBatchId));

            if (index === -1) {
                positions.push({
                    ...product,
                    position: positions.length ? lastItem.position + 1 : 0
                });
            }
        });

        formik.setFieldValue('positions', positions);
        onClose();
        // eslint-disable-next-line
    }, [chosenProductsWithPrices]);

    const onProductsInputChange = (product) => {
        if (product.length >= 3) {
            setProductsOptions([]);
            setLoadingProductsOptions(true);

            getProductsDebounced(product);
        }
    };

    const getProductsDebounced = _.debounce((query) => {
        dispatch(getWarehouseProductBatchesAction({ sourceWarehouseId, query: { query } }))
            .then(response => {
                showErrorMessage(getWarehouseProductBatchesNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    const newProductOptions = mapProductsOptions(response?.payload).concat(chosenProducts);
                    const newDistinctProductOptions = [...new Map(newProductOptions.map((option) => [option['value'], option])).values()];
                    setProductsOptions(newDistinctProductOptions);
                }
                setLoadingProductsOptions(false);
            });
    }, 600);

    const onAddProducts = () => {
        if (chosenProducts.length === 0) {
            onClose();
        }

        const productsForValidation = formik?.values?.productsForValidation || [];

        chosenProducts.forEach(product => {
            dispatch(getPriceByDateAction({ productId: product?.details?.productId, productBatchId: product?.details?.productBatchId, podId: form?.podId, customerId, date: form?.theftDate?.format(MISC_DATE_ISO_FORMAT) }))
                .then(response => {
                    showErrorMessage(getPriceByDateNotificationData(response));

                    if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                        setChosenProductsWithPrices(prevState => [...prevState,
                            {
                                ...product?.details,
                                quantity: parseInt(quantityRef?.current?.value) || 0,
                                vat: response?.payload?.vat,
                                basicPrice: response?.payload?.basicGrossPrice,
                                sellingPrice: response?.payload?.sellingGrossPrice,
                                shopAdminSellingPrice: response?.payload?.sellingGrossPrice,
                                productPromotionId: response?.payload?.productPromotionId,
                                expirationPromotionId: response?.payload?.expirationPromotionId,
                                productDiscountAmount: response?.payload?.productDiscountAmount,
                                expirationDiscountAmount: response?.payload?.expirationDiscountAmount,
                            }
                        ]);
                    }
                });

            const productIndexValidation = productsForValidation?.findIndex(p => (p?.product?.id === product?.original?.product?.id) && (p?.productBatchId === product?.original?.id));

            if (productIndexValidation === -1) {
                productsForValidation.push(product?.original);
            }
        });

        formik.setFieldValue('productsForValidation', productsForValidation);
    };

    return (
        <>
            <Box sx={ { width: '200px' } } onClick={ () => setOpen(true) } mt={ 4 }>
                <Stack direction="row" alignItems="center" gap={ 1 } sx={ { cursor: 'pointer' } }>
                    <AddCircleIcon/>
                    <Typography fontWeight={ 'bold' }>Dodaj produkty</Typography>
                </Stack>
            </Box>
            <Dialog PaperProps={ { sx: { maxHeight: 800 } } } open={ open } onClose={ onClose } fullWidth maxWidth={ 'md' }>
                <DialogTitle>Dodaj produkty</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Grid container rowGap={ 4 }>
                        <Grid container item rowGap={ 4 }>
                            <TextField
                                name="quantity"
                                label="Ilość"
                                type="number"
                                inputRef={ quantityRef }
                            />
                            <AutocompleteMultipleSearchOnType
                                options={ productsOptions }
                                value={ chosenProducts }
                                setValue={ (newValue) => setChosenProducts(newValue) }
                                label="Produkty - wiele (wpisz min. 3 litery)"
                                onInputChange={ (value) => onProductsInputChange(value) }
                                required
                                loading={ loadingProductsOptions }
                            />
                        </Grid>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <DialogActions>
                                <Button onClick={ onClose }>Zamknij</Button>
                                <Button onClick={ onAddProducts } isLoading={ getPriceByDate?.loading }>Zapisz</Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddProductsDialog;
