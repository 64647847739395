import React, { useState } from 'react';

import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { SelectFieldMultiple } from '../../../../common/select_field';
import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import { getReservationStatusOptions } from '../../../../common/utils/reservation_utils';
import { ReservationStatusEnum } from '../../../../../core/enums/reservation/reservation';

const ReservationFilters = ({ from, onChangeTimeFrom, to, onChangeTimeTo, onSelectReservationStatusesChange, onSelectPodsChange }) => {
    const getAllPods = useSelector(getAllPodsState);

    const [podIds, setPodIds] = useState([]);
    const [reservationStatuses, setReservationStatuses] = useState([ReservationStatusEnum.CONFIRMED.value, ReservationStatusEnum.PAID.value]);

    return (
        <Grid container mb={ 4 } gap={ 4 }>
            <Grid container gap={ 4 }>
                <Grid item>
                    <DatePicker
                        displayWeekNumber
                        value={ from }
                        label="Data od"
                        onChange={ newDate => onChangeTimeFrom(newDate) }
                    />
                </Grid>
                <Grid item>
                    <DatePicker
                        displayWeekNumber
                        value={ to }
                        label="Data do"
                        onChange={ newDate => onChangeTimeTo(newDate) }
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={ 9 }>
                    <SelectFieldMultiple
                        label="Pokaż po statusie"
                        options={ getReservationStatusOptions }
                        required
                        value={ reservationStatuses }
                        onClose={ () => onSelectReservationStatusesChange(reservationStatuses) }
                        onChange={ event => setReservationStatuses(event.target.value) }
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={ 9 }>
                    <SelectFieldMultiple
                        label="Pokaż dla podów"
                        options={ getAllPods?.data?.map(pod => ({ value: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') })) }
                        required
                        value={ podIds }
                        onClose={ () => onSelectPodsChange(podIds) }
                        onChange={ event => setPodIds(event.target.value) }
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ReservationFilters;