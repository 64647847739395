import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import useNotification from '../../../core/hooks/use_notification';
import { getPodsAction, getPodsState } from "../../../features/pod/get_pods";
import { getPodReservationConfigsNotificationData, getPodsNotificationData } from "../../../core/utils/notification_utils";
import { getDefaultPodsPaginationQueryValues } from "../../../core/utils/api_utils";
import PaginationControls from "../../common/pagination_controls";
import deepmerge from "deepmerge";
import { getPodReservationConfigsAction, getPodReservationConfigsState } from "../../../features/reservation/get_pod_reservation_configs";
import { ApiRequestStatusEnum } from "../../../core/enums/common/api";
import { getSupportedPaymentType } from "../../common/utils/reservation_utils";
import UpdatePodReservationConfigDialog from "./form/update_pod_reservation_config_dialog";

const initialQueryParams = {
    ...getDefaultPodsPaginationQueryValues
};

const Settings = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getPods = useSelector(getPodsState);
    const getPodConfigs = useSelector(getPodReservationConfigsState);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const refreshList = useCallback(() => {
        dispatch(getPodsAction(queryParams))
            .then(response => {
                showErrorMessage(getPodsNotificationData(response));

                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    const podIds = response?.payload?.content?.map(pod => pod?.id);

                    dispatch(getPodReservationConfigsAction(podIds))
                        .then(response => showErrorMessage(getPodReservationConfigsNotificationData(response)));
                }
            });
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [dispatch, refreshList, showErrorMessage]);

    function getReservationEnabled(podId) {
        return getPodConfigs?.data?.find(podConfig => podConfig?.podId === podId)?.enabled ? 'tak' : 'nie';
    }

    function getReservationSupportedPaymentTypes(podId) {
        return getPodConfigs?.data?.find(podConfig => podConfig?.podId === podId)?.supportedPaymentTypes?.map(paymentType => getSupportedPaymentType(paymentType)).join(", ");
    }

    return (
        <Grid>
            {
                (getPods?.loading || getPodConfigs?.loading) &&
                <LinearProgress/>
            }
            {
                getPods?.data?.content && getPodConfigs?.data &&
                <Grid container>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Partner</TableCell>
                                    <TableCell>Urządzenie</TableCell>
                                    <TableCell>Opis</TableCell>
                                    <TableCell>Adres</TableCell>
                                    <TableCell align="center">Wspiera sprzedaż stacjonarną</TableCell>
                                    <TableCell align="center">Rezerawacje aktywne</TableCell>
                                    <TableCell align="center">Wspierane sposoby płatności rezerwacji</TableCell>
                                    <TableCell align="center">Edycja</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getPods?.data?.content?.map(pod => (
                                        <TableRow
                                            key={ pod?.id }
                                            hover
                                        >
                                            <TableCell>
                                                {
                                                    pod?.indoorPartner?.name
                                                }
                                            </TableCell>
                                            <TableCell>{ pod?.device?.serviceId || '-' }</TableCell>
                                            <TableCell>{ pod?.description }</TableCell>
                                            <TableCell>
                                                {
                                                    pod?.address
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    pod?.supportStationarySales ? "tak" : "nie"
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    getReservationEnabled(pod?.id)
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    getReservationSupportedPaymentTypes(pod?.id)
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    <UpdatePodReservationConfigDialog
                                                        podId={ pod?.id }
                                                        refreshList={ refreshList }
                                                    />
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getPods?.data?.pageable?.pageNumber }
                            totalPages={ getPods?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default Settings;
