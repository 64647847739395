import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Grid, Tab, Tabs } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';

import { ROUTE_CUSTOMERS } from '../../../../core/constants';
import { Button } from '../../../common/button';
import CustomerDetails from './customer_details';
import ActivitiesList from './activities_list';
import TheftRegistriesList from './theft_registries_list';

const CustomerPage = () => {
    const navigate = useNavigate();
    const { customerId } = useParams();
    const location = useLocation();

    const [tab, setTab] = useState(location?.state?.tab || 0);

    const renderView = () => {
        if (tab === 0) {
            return <CustomerDetails customerId={ customerId }/>;
        } else if (tab === 1) {
            return <ActivitiesList customerId={ customerId }/>;
        } else if (tab === 2) {
            return <TheftRegistriesList customerId={ customerId } tab={ tab }/>;
        }

        return <></>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_CUSTOMERS, { state: { queryParams: location?.state?.queryParams } }) }>
                        Wróć
                    </Button>
                </Grid>
                <Grid container item>
                    <Tabs
                        value={ tab }
                        onChange={ (_, value) => setTab(value) }
                        variant="scrollable"
                        scrollButtons
                        sx={ {
                            [`& .${ tabsClasses.scrollButtons }`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        } }
                    >
                        <Tab label="Informacje ogólne"/>
                        <Tab label="Aktywności"/>
                        <Tab label="Kartoteka"/>
                    </Tabs>
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default CustomerPage;