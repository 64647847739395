import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useNotification from '../../../../../core/hooks/use_notification';
import { getFoodPartnerState } from '../../../../../features/food_partner/get_food_partner';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import TextFieldFormik from '../../../../common/text_field';
import RadioGroupFormik from '../../../../common/radio_group';
import { SubmitButton } from '../../../../common/button';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { GET_ROUTE_FOOD_PARTNER_FORMS } from '../../../../../core/constants';
import { createFoodPartnerAction, createFoodPartnerState } from '../../../../../features/food_partner/create_food_partner';
import { updateFoodPartnerAction, updateFoodPartnerState } from '../../../../../features/food_partner/update_food_partner';
import { createFoodPartnerNotificationData, updateFoodPartnerNotificationData } from '../../../../../core/utils/notification_utils';
import { yesNoOptions } from '../../../../common/form_utils';

const foodDeliveryTypeOptions = [
    { value: 'DELIVERY_TO_WAREHOUSE', label: 'Dostawa do magazynu głównego' },
    { value: 'SELF_PICKUP', label: 'Odbiór osobisty' },
];

const FoodPartnerForm = ({ foodPartnerId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showNotification } = useNotification();

    const createForm = !foodPartnerId;

    const getFoodPartner = useSelector(getFoodPartnerState);
    const createFoodPartner = useSelector(createFoodPartnerState);
    const updateFoodPartner = useSelector(updateFoodPartnerState);

    const initialValues = {
        name: (!createForm && getFoodPartner?.data?.name) || '',
        shortName: (!createForm && getFoodPartner?.data?.shortName) || '',
        address: (!createForm && getFoodPartner?.data?.address) || '',
        foodDeliveryType: (!createForm && getFoodPartner?.data?.foodDeliveryType) || foodDeliveryTypeOptions[0].value,
        deliveryNote: (!createForm && getFoodPartner?.data?.deliveryNote) || '',
        orderNote: (!createForm && getFoodPartner?.data?.orderNote) || '',
        nip: (!createForm && getFoodPartner?.data?.nip) || '',
        reservationEnabled: (!createForm && getFoodPartner?.data?.reservationEnabled) || false,
        supportWeekendOrdering: (!createForm && getFoodPartner?.data?.supportWeekendOrdering) || false,
        tryUseExistingResources: (!createForm && getFoodPartner?.data?.tryUseExistingResources) || false,
        generalNote: (!createForm && getFoodPartner?.data?.generalNote) || ''
    };

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        shortName: Yup.string(),
        address: Yup.string(),
        foodDeliveryType: Yup.string().required(),
        deliveryNote: Yup.string(),
        orderNote: Yup.string(),
        nip: Yup.string().required(),
        reservationEnabled: Yup.boolean().required(),
        supportWeekendOrdering: Yup.boolean().required(),
        tryUseExistingResources: Yup.boolean().required(),
        generalNote: Yup.string()
    });

    const onSubmit = (values) => {
        const form = { ...values };

        if (createForm) {
            dispatch(createFoodPartnerAction({ form }))
                .then(response => {
                    showNotification(createFoodPartnerNotificationData(response));
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && navigate(GET_ROUTE_FOOD_PARTNER_FORMS(response?.payload.id));
                });
        } else {
            dispatch(updateFoodPartnerAction({ form, id: foodPartnerId }))
                .then(response => {
                    showNotification(updateFoodPartnerNotificationData(response));
                });
        }
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmit }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 2 }>
                    <Grid container item>
                        <TextFieldFormik name="name" label="Nazwa" required/>
                    </Grid>
                    <Grid container item>
                        <TextFieldFormik name="shortName" label="Nazwa skrócona"/>
                    </Grid>
                    <Grid container item>
                        <TextFieldFormik name="nip" label="NIP" required/>
                    </Grid>
                    <Grid container item>
                        <RadioGroupFormik
                            name="foodDeliveryType"
                            label="Forma dostawy"
                            options={ foodDeliveryTypeOptions }
                            booleanOptions={ false }
                        />
                    </Grid>
                    <Grid container item>
                        <TextFieldFormik name="deliveryNote" label="Dni i godziny dostaw / odbiorów"/>
                    </Grid>
                    <Grid container item>
                        <TextFieldFormik name="orderNote" label="Ile dni wcześniej i do której godziny trzeba składać zamówienia"/>
                    </Grid>
                    <Grid container item>
                        <RadioGroupFormik
                            name="reservationEnabled"
                            label="Czy użytkownicy mogą składać zamówienia?"
                            options={ yesNoOptions }
                        />
                    </Grid>
                    <Grid container item>
                        <RadioGroupFormik
                            name="supportWeekendOrdering"
                            label="Wspiera składanie zamówień w weekend?"
                            options={ yesNoOptions }
                        />
                    </Grid>
                    <Grid container item>
                        <RadioGroupFormik
                            name="tryUseExistingResources"
                            label="Podczas generowania dokumentów na podstawie rezerwacji wykorzystywać istniejacę sztuki produktów?"
                            options={ yesNoOptions }
                        />
                    </Grid>
                    <Grid container item>
                        <TextFieldFormik name="generalNote" label="Notatka" multiline rows={ 3 }/>
                    </Grid>
                    <Grid container item mt={ 2 } justifyContent="flex-end">
                        <SubmitButton isLoading={ createForm ? createFoodPartner?.loading : updateFoodPartner?.loading }>Zapisz</SubmitButton>
                    </Grid>
                </Grid>
            }
        </Formik>
    );

};

export default FoodPartnerForm;
