import { combineReducers } from '@reduxjs/toolkit';

import getProductsAvailability from './get_products_availability';
import getProductsAvailabilitySingleDay from './get_products_availability_single_day';
import createProductAvailability from './create_product_availability';
import deleteProductAvailability from './delete_product_availability';
import importProductAvailability from './import_product_availability';
import getReservations from './get_reservations';
import deleteReservation from './delete_reservation';
import updateProductQuantityLimit from './update_prduct_quantity_limit';
import updateReservationStoreStatus from './update_reservation_store_status';
import getPodReservationConfigs from './get_pod_reservation_configs';
import updatePodReservationConfig from './update_pod_reservation_config';

export default combineReducers({
    getProductsAvailability, getProductsAvailabilitySingleDay, createProductAvailability, deleteProductAvailability, importProductAvailability,
    getReservations, deleteReservation, updateProductQuantityLimit, updateReservationStoreStatus,
    getPodReservationConfigs, updatePodReservationConfig
});
