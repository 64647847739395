import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { CART_EVENTS_PATH } from '../../core/constants';
import { buildQueryString, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getCartEventsAction = createAsyncThunk(
    'order/getCartEventsAction',
    async (queryParams, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ CART_EVENTS_PATH }${ buildQueryString(queryParams) }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getCartEventsSlice = createSlice({
    name: 'getCartEventsSlice',
    initialState: DEFAULT_STATE,
    reducers: {
        invalidateCartEvents() {
            return DEFAULT_STATE;
        }
    },
    extraReducers: builder => {
        builder.addCase(getCartEventsAction.pending, () => STATE__API__STARTED);
        builder.addCase(getCartEventsAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getCartEventsAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const { invalidateCartEvents } = getCartEventsSlice.actions;
export const getCartEventsState = (state) => state.order.getCartEvents;
export default getCartEventsSlice.reducer;