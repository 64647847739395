import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import _ from 'lodash';

import { getWarehouseDocumentAction, getWarehouseDocumentState } from '../../../../../features/inventory/get_warehouse_document';
import useNotification from '../../../../../core/hooks/use_notification';
import { MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_DATE_ONLY_FORMAT } from '../../../../../core/constants';
import TextFieldFormik from '../../../../common/text_field';
import { SubmitButton } from '../../../../common/button';
import { confirmDeliveryDocumentAction, confirmDeliveryDocumentState } from '../../../../../features/inventory/confirm_delivery_document';
import { updateAndConfirmDeliveryDocumentAction, updateAndConfirmDeliveryDocumentState } from '../../../../../features/inventory/update_and_confirm_delivery_document';
import { confirmDeliveryDocumentNotificationData, getWarehouseDocumentNotificationData, updateAndConfirmDeliveryDocumentNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';

const DetailsForm = ({ podId, queryParams }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { showNotification, showErrorMessage } = useNotification();
    const navigate = useNavigate();
    const location = useLocation();

    const getWarehouseDocument = useSelector(getWarehouseDocumentState);
    const confirmDeliveryDocument = useSelector(confirmDeliveryDocumentState);
    const updateAndConfirmDeliveryDocument = useSelector(updateAndConfirmDeliveryDocumentState);

    const initialValues = {
        productBatches: getWarehouseDocument?.data?.positions?.map(item => (
            {
                name: item?.product?.name,
                expiryDate: item?.expiryDate,
                netPrice: item?.netPrice?.toFixed(2),
                grossPrice: item?.grossPrice?.toFixed(2),
                productId: item?.product?.id,
                productBatchId: item?.productBatchId,
                quantity: item?.quantity,
                maxQuantity: item?.quantity
            }
        ))
    };

    const schema = Yup.object().shape({
        productBatches: Yup.array().of(
            Yup.object().shape(
                {
                    productId: Yup.number().required(),
                    productBatchId: Yup.string().required(),
                    quantity: Yup.number().required()
                }
            )
        )
    });

    const onRemoveProduct = (formik, index) => {
        const productBatches = formik?.values?.productBatches;
        const productBatch = formik?.values?.productBatches[index];

        if (index !== -1) {
            productBatches.splice(index, 1, { ...productBatch, quantity: 0 });
            formik.setFieldValue('productBatches', productBatches);
        }
    };

    const onSubmit = (values) => {
        const shouldUpdate = values?.productBatches?.some(item => item?.quantity < item?.maxQuantity);

        if (shouldUpdate) {
            const form = { productBatches: values?.productBatches?.map(item => _.pick(item, ['productId', 'productBatchId', 'quantity'])) };
            const isEmptyDocument = form?.productBatches?.filter(item => item?.quantity > 0)?.length === 0;

            if (isEmptyDocument) {
                enqueueSnackbar('Brak pozycji na dokumencie', { variant: 'error' });
                return;
            }

            dispatch(updateAndConfirmDeliveryDocumentAction({ id: getWarehouseDocument?.data?.id, podId, form }))
                .then(response => {
                    showNotification((updateAndConfirmDeliveryDocumentNotificationData(response)));

                    if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                        dispatch(getWarehouseDocumentAction(getWarehouseDocument?.data?.id))
                            .then(response => {
                                showErrorMessage((getWarehouseDocumentNotificationData(response)));
                                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && navigate(location.pathname, { state: { queryParams, confirmed: true } });
                            });
                    }
                });
        } else {
            dispatch(confirmDeliveryDocumentAction({ id: getWarehouseDocument?.data?.id, podId }))
                .then(response => {
                    showNotification((confirmDeliveryDocumentNotificationData(response)));

                    if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                        dispatch(getWarehouseDocumentAction(getWarehouseDocument?.data?.id))
                            .then(response => {
                                showErrorMessage((getWarehouseDocumentNotificationData(response)));
                                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && navigate(location.pathname, { state: { queryParams, confirmed: true } });
                            });
                    }
                });
        }
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmit }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 2 }>
                    <Grid container>
                        <Typography variant="h2">Pozycje dokumentu</Typography>
                    </Grid>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Produkt</TableCell>
                                    <TableCell align="center">Ilość</TableCell>
                                    <TableCell align="center">Partia</TableCell>
                                    <TableCell align="center">Data ważności</TableCell>
                                    <TableCell align="center">Cena netto</TableCell>
                                    <TableCell align="center">Cena brutto</TableCell>
                                    <TableCell align="center">Usuń</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FieldArray name="productBatches">
                                    { () => (
                                        formik?.values?.productBatches?.map((item, index) => (
                                            <TableRow
                                                key={ index }
                                                hover
                                                sx={ { display: item?.quantity === 0 && 'none' } }
                                            >
                                                <TableCell>{ item?.name }</TableCell>
                                                <TableCell>
                                                    <TextFieldFormik
                                                        name={ `productBatches.${ index }.quantity` }
                                                        type="number"
                                                        inputProps={ { min: '0', max: item?.maxQuantity, step: '1' } }
                                                        required
                                                    />
                                                </TableCell>
                                                <TableCell align="center">{ item?.productBatchId }</TableCell>
                                                <TableCell align="center">
                                                    {
                                                        moment(item?.expiryDate, MISC_DATE_ISO_FORMAT)
                                                            .format(MISC_DATE_POLISH_DATE_ONLY_FORMAT)
                                                    }
                                                </TableCell>
                                                <TableCell align="right">{ item?.netPrice }</TableCell>
                                                <TableCell align="right">{ item?.grossPrice }</TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        onClick={ () => onRemoveProduct(formik, index) }
                                                        title="Usuń">
                                                        <RemoveCircleIcon/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) }
                                </FieldArray>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container item mt={ 2 } justifyContent="flex-end">
                        <SubmitButton isLoading={ confirmDeliveryDocument?.loading || updateAndConfirmDeliveryDocument?.loading }>Zatwierdź dokument</SubmitButton>
                    </Grid>
                </Grid>
            }
        </Formik>
    );
};

export default DetailsForm;
