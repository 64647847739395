import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogActions from '@mui/material/DialogActions';
import { Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import useNotification from '../../../../core/hooks/use_notification';
import { Button, SubmitButton } from '../../../common/button';
import { updatePodReservationConfigNotificationData } from '../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { updatePodReservationConfigAction, updatePodReservationConfigState } from "../../../../features/reservation/update_pod_reservation_config";
import { getPodReservationConfigsState } from "../../../../features/reservation/get_pod_reservation_configs";
import { getReservationPaymentTypeOptions } from "../../../common/utils/reservation_utils";
import RadioGroupFormik from "../../../common/radio_group";
import { yesNoOptions } from "../../../common/form_utils";
import { SelectFieldMultipleFormik } from "../../../common/select_field";
import { EditableTable } from "../../../common/editable_table";

const UpdatePodReservationConfigDialog = ({ podId, refreshList }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const getPodConfigs = useSelector(getPodReservationConfigsState);
    const updatePodReservationConfig = useSelector(updatePodReservationConfigState);

    const [open, setOpen] = useState(false);

    const onClose = () => setOpen(false);

    const initialValues = {
        enabled: getPodConfigs?.data?.find(podConfig => podConfig?.podId === podId)?.enabled,
        supportedPaymentType: getPodConfigs?.data?.find(podConfig => podConfig?.podId === podId)?.supportedPaymentTypes,
        segments: getPodConfigs?.data?.find(podConfig => podConfig?.podId === podId)?.segments
    };

    const schema = Yup.object().shape({
        enabled: Yup.bool().required(),
        supportedPaymentType: Yup.array().notRequired(),
        segments: Yup.array().notRequired()
    });

    const onSubmit = (values) => {
        dispatch(updatePodReservationConfigAction({ podId, enabled :
            values.enabled,
            supportedPaymentTypes : values.supportedPaymentType,
            segments : values.segments.map(segment => ({ name : segment.name, numberOfShelves : segment.numberOfShelves }) ) }))
            .then(response => {
                showNotification(updatePodReservationConfigNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    refreshList && refreshList();
                    onClose();
                }
            });
    };

    return (
        <>
            <EditIcon onClick={ () => setOpen(true) } cursor="pointer"/>
            <Dialog PaperProps={ { sx: { maxHeight: 800 } } } open={ open } onClose={ onClose } fullWidth maxWidth={ 'sm' }>
                <DialogTitle>Zaktualizuj konfiguracje</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={ schema }
                        validateOnChange={ false }
                        validateOnBlur={ false }
                        onSubmit={ onSubmit }>
                        { (formik) =>
                            <Grid component={ Form } container rowGap={ 4 }>
                                <Grid container item>
                                    <RadioGroupFormik
                                        name="enabled"
                                        label="Czy rezerwacje aktywne"
                                        options={ yesNoOptions }
                                    />
                                </Grid>
                                <Grid container item>
                                    <SelectFieldMultipleFormik
                                        name="supportedPaymentType"
                                        label="Wspierany sposób płatności rezerwacji"
                                        options={ getReservationPaymentTypeOptions }
                                    />
                                </Grid>
                                <Grid container item>
                                    <EditableTable
                                        onChange={ (value) => formik.setFieldValue('segments', value) }
                                        initialRows={ initialValues.segments }
                                        columns=
                                            {[
                                                { field: 'name', headerName: 'Nazwa półki', defaultValue: '', width: 180, editable: true },
                                                { field: 'numberOfShelves', headerName: 'Liczba półek', defaultValue: 1, width: 180, editable: true }
                                            ]}
                                    />
                                </Grid>
                                <Grid container item mt={ 2 } justifyContent="flex-end">
                                    <DialogActions sx={ { gap: 2 } }>
                                        <Button onClick={ onClose }>Zamknij</Button>
                                        <SubmitButton isLoading={ updatePodReservationConfig?.loading }>Zapisz</SubmitButton>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default UpdatePodReservationConfigDialog;