import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { THEFT_REGISTRIES_PATH } from '../../core/constants';
import { buildQueryString, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getTheftRegistriesAction = createAsyncThunk(
    'order/getTheftRegistriesAction',
    async (queryParams, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ THEFT_REGISTRIES_PATH }${ buildQueryString(queryParams) }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getTheftRegistriesSlice = createSlice({
    name: 'getTheftRegistriesSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getTheftRegistriesAction.pending, () => STATE__API__STARTED);
        builder.addCase(getTheftRegistriesAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getTheftRegistriesAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getTheftRegistriesState = (state) => state.order.getTheftRegistries;
export default getTheftRegistriesSlice.reducer;
