import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import _ from 'lodash';
import deepmerge from 'deepmerge';

import useNotification from '../../../../core/hooks/use_notification';
import { getFoodPartnerOrdersNotificationData } from '../../../../core/utils/notification_utils';
import { getDefaultPaginationQueryValues } from '../../../../core/utils/api_utils';
import SelectField from '../../../common/select_field';
import FoodPartnerOrdersList from './food_partner_orders_list';
import { getFoodPartnerOrdersAction, getFoodPartnerOrdersState } from '../../../../features/inventory/get_food_partner_orders';
import PaginationControls from '../../../common/pagination_controls';
import { getFoodPartnerOrderStatusOptions } from '../../../common/utils/food_partner_order_utils';
import { Button } from '../../../common/button';
import { ROUTE_FOOD_PARTNER_ORDERS_NEW } from '../../../../core/constants';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    status: 'ALL',
    sort: ['createDate', 'desc']
};

const FoodPartnerOrdersPage = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();

    const getFoodPartnerOrders = useSelector(getFoodPartnerOrdersState);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const refreshList = useCallback(() => {
        dispatch(getFoodPartnerOrdersAction(queryParams))
            .then(response => showErrorMessage(getFoodPartnerOrdersNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [refreshList]);

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } rowGap={ 4 } justifyContent="space-between">
                <Grid container>
                    <Typography variant="h1">Zamówienia do dostawców</Typography>
                </Grid>
                <Grid container>
                    <Grid item>
                        <SelectField
                            label={ 'Pokaż' }
                            value={ _.pick(queryParams, ['status']).status }
                            onChange={ (event) => setQueryParams(prevState => deepmerge(prevState, { status: event.target.value })) }
                            options={ [{ value: 'ALL', label: 'Wszystkie' }, ...getFoodPartnerOrderStatusOptions] }
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Button onClick={ () => navigate(ROUTE_FOOD_PARTNER_ORDERS_NEW) }>
                        Dodaj zamówienie
                    </Button>
                </Grid>
            </Grid>
            {
                getFoodPartnerOrders?.loading &&
                <LinearProgress/>
            }
            {
                getFoodPartnerOrders?.data?.content &&
                <>
                    <FoodPartnerOrdersList
                        refreshList={ refreshList }/>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getFoodPartnerOrders?.data?.pageable?.pageNumber }
                            totalPages={ getFoodPartnerOrders?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </>
            }
        </Grid>
    );
};

export default FoodPartnerOrdersPage;
