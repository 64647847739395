import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import _ from 'lodash';
import moment from 'moment/moment';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import { getAllInventoryProductsNotificationData, getWarehouseProductBatchesNotificationData } from '../../../../core/utils/notification_utils';
import useNotification from '../../../../core/hooks/use_notification';
import { getWarehouseProductBatchesAction, getWarehouseProductBatchesState, invalidateProductBatches } from '../../../../features/inventory/get_warehouse_product_batches';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_POLISH_DATE_ONLY_FORMAT } from '../../../../core/constants';
import { Button } from '../../../common/button';
import AutocompleteMultipleSearchOnType from '../../../common/autocomplete';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { getProductsForDocumentAction, getProductsForDocumentState, invalidateProductsForDocument } from '../../../../features/product/get_products_for_document';

const AddProductsDialog = ({ foodPartnerId, formik, productsListIndex, dateToCalculateExpirationDate, onlyDelivery, sourceWarehouseId }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getDocumentProducts = useSelector(!foodPartnerId ? getWarehouseProductBatchesState : getProductsForDocumentState);

    const quantityRef = useRef(null);

    const getLabel = useCallback((product) => {
        return onlyDelivery
            ? product?.product?.name
            : product?.name;
    }, [onlyDelivery]);

    const getSecondaryLabel = useCallback((product) => {
        return onlyDelivery && 'Data przydatności: ' + moment(product?.expiryDate, MISC_DATE_ISO_DATE_ONLY_FORMAT).format(MISC_DATE_POLISH_DATE_ONLY_FORMAT) + ', max liczba szt.: ' + product?.quantity;
    }, [onlyDelivery]);

    const mapProductsOptions = useCallback((data) => {
        return data?.map(resource => ({
            key: onlyDelivery ? resource?.product?.id + resource?.productBatchId : resource?.id,
            value: onlyDelivery ? resource?.product?.id + '_' + resource?.productBatchId : resource?.id,
            label: getLabel(resource),
            secondaryLabel: getSecondaryLabel(resource),
            details: {
                name: onlyDelivery ? resource?.product?.name : resource?.name,
                productId: onlyDelivery ? resource?.product?.id : resource?.id,
                productBatchId: onlyDelivery ? resource?.productBatchId : '',
                quantity: '',
                expirationDate: onlyDelivery ? moment(resource?.expiryDate)
                    : (resource?.defaultDaysToExpiration && dateToCalculateExpirationDate)
                        ? moment(dateToCalculateExpirationDate).add(Math.max(resource?.defaultDaysToExpiration - 1, 0), 'days')
                        : '',
                price: onlyDelivery ? '' : (resource?.purchaseNetPrice || ''),
                type: onlyDelivery ? resource?.product?.type : resource?.type
            },
            original: resource
        }));
    }, [getLabel, getSecondaryLabel, dateToCalculateExpirationDate, onlyDelivery]);

    const [open, setOpen] = useState(false);
    const [productsOptions, setProductsOptions] = useState(mapProductsOptions(getDocumentProducts?.data) || []);
    const [loadingProductsOptions, setLoadingProductsOptions] = useState(false);
    const [chosenProducts, setChosenProducts] = useState([]);

    const onProductsInputChange = (product) => {
        if (product.length >= 3) {
            setProductsOptions([]);
            setLoadingProductsOptions(true);

            getProductsDebounced(product);
        }
    };

    const getProductsDebounced = _.debounce((query) => {
        if (onlyDelivery) {
            dispatch(getWarehouseProductBatchesAction({ sourceWarehouseId, query: { query } }))
                .then(response => {
                    showErrorMessage(getWarehouseProductBatchesNotificationData(response));
                    if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                        const newProductOptions = mapProductsOptions(response?.payload).concat(chosenProducts);
                        const newDistinctProductOptions = [...new Map(newProductOptions.map((option) => [option['value'], option])).values()];
                        setProductsOptions(newDistinctProductOptions);
                    }
                    setLoadingProductsOptions(false);
                });
        } else {
            dispatch(getProductsForDocumentAction({ foodPartnerId, query }))
                .then(response => {
                    showErrorMessage(getAllInventoryProductsNotificationData(response));
                    if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                        const newProductOptions = mapProductsOptions(response?.payload).concat(chosenProducts);
                        const newDistinctProductOptions = [...new Map(newProductOptions.map((option) => [option['value'], option])).values()];
                        setProductsOptions(newDistinctProductOptions);
                    }
                    setLoadingProductsOptions(false);
                });
        }
    }, 600);

    const onClose = () => {
        setOpen(false);
        dispatch(invalidateProductBatches());
        dispatch(invalidateProductsForDocument());
        setChosenProducts([]);
        setProductsOptions([]);
    };

    const onAddProducts = () => {
        if (chosenProducts.length === 0) {
            onClose();
        }

        const productsList = formik?.values?.productsList[productsListIndex] || [];
        const productsForValidation = formik?.values?.productsForValidation || [];

        chosenProducts.forEach(product => {
            const lastItem = productsList[productsList.length - 1];
            const index = onlyDelivery
                ? productsList?.findIndex(p => (p?.productId === product?.original?.product?.id) && (p?.productBatchId === product?.original?.id))
                : productsList?.findIndex(p => p?.productId === product?.original?.id);

            if (index === -1) {
                productsList.push({
                    ...product?.details,
                    position: productsList.length ? lastItem.position + 1 : 0,
                    quantity: parseInt(quantityRef?.current?.value) || 0
                });
            }

            if (onlyDelivery) {
                const index = productsForValidation?.findIndex(p => (p?.product?.id === product?.original?.product?.id) && (p?.productBatchId === product?.original?.id));

                if (index === -1) {
                    productsForValidation.push(product?.original);
                }
            }
        });

        formik.setFieldValue(`productsList.${ productsListIndex }`, productsList);
        onlyDelivery && formik.setFieldValue('productsForValidation', productsForValidation);
        onClose();
    };

    return (
        <>
            <Box sx={ { width: '200px' } } onClick={ () => setOpen(true) } mt={ 4 }>
                <Stack direction="row" alignItems="center" gap={ 1 } sx={ { cursor: 'pointer' } }>
                    <AddCircleIcon/>
                    <Typography fontWeight={ 'bold' }>Dodaj produkty</Typography>
                </Stack>
            </Box>
            <Dialog PaperProps={ { sx: { maxHeight: 800 } } } open={ open } onClose={ onClose } fullWidth maxWidth={ 'md' }>
                <DialogTitle>Dodaj produkty</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Grid container rowGap={ 4 }>
                        <Grid container item rowGap={ 4 }>
                            <TextField
                                name="quantity"
                                label="Ilość"
                                type="number"
                                inputRef={ quantityRef }
                            />
                            <AutocompleteMultipleSearchOnType
                                options={ productsOptions }
                                value={ chosenProducts }
                                setValue={ (newValue) => setChosenProducts(newValue) }
                                label="Produkty - wiele (wpisz min. 3 litery)"
                                onInputChange={ (value) => onProductsInputChange(value) }
                                required
                                loading={ loadingProductsOptions }
                            />
                        </Grid>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <DialogActions>
                                <Button onClick={ onClose }>Zamknij</Button>
                                <Button onClick={ onAddProducts }>Zapisz</Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddProductsDialog;
