import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import SmsIcon from '@mui/icons-material/Sms';
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';

import { getIncorrectEmailsState } from '../../../features/customer/get_incorrect_emails';
import { MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT } from '../../../core/constants';
import { getIncorrectEmailStatus } from '../../common/utils/incorrect_email';
import ConfirmDialog from '../common/confirm_dialog';
import { createIncorrectEmailSmsAction, createIncorrectEmailSmsState } from '../../../features/customer/create_incorrect_email_sms';
import { createIncorrectEmailSmsNotificationData } from '../../../core/utils/notification_utils';
import useNotification from '../../../core/hooks/use_notification';

const IncorrectEmailsList = () => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const getIncorrectEmails = useSelector(getIncorrectEmailsState);
    const createIncorrectEmailSms = useSelector(createIncorrectEmailSmsState);

    const onSendSms = (id) => {
        dispatch(createIncorrectEmailSmsAction(id))
            .then(response => showNotification(createIncorrectEmailSmsNotificationData(response)));
    };

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">ID klienta</TableCell>
                        <TableCell align="center">Data utworzenia </TableCell>
                        <TableCell align="center">Data aktualizacji</TableCell>
                        <TableCell align="center">Nieprawidłowy email</TableCell>
                        <TableCell align="center">Poprawiony email</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Wysłano sms</TableCell>
                        <TableCell align="center">Wyślij sms</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getIncorrectEmails?.data?.content?.map(incorrectEmail => (
                            <TableRow
                                key={ incorrectEmail?.id }
                                hover
                            >
                                <TableCell>
                                    {
                                        incorrectEmail?.customerId
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        moment(incorrectEmail?.createdDate, MISC_DATE_ISO_FORMAT)
                                            .format(MISC_DATE_POLISH_FORMAT)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        incorrectEmail?.updateDate &&
                                        moment(incorrectEmail?.updateDate, MISC_DATE_ISO_FORMAT)
                                            .format(MISC_DATE_POLISH_FORMAT)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        incorrectEmail?.incorrectEmail
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        incorrectEmail?.correctedEmail
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        getIncorrectEmailStatus(incorrectEmail?.status)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        incorrectEmail?.smsSent
                                            ? <CheckIcon color="success"/>
                                            : <ClearIcon color="error"/>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <ConfirmDialog
                                        isLoading={ createIncorrectEmailSms?.loading }
                                        maxWidth="sm"
                                        title={ incorrectEmail?.smsSent ? 'Czy na pewno chcesz ponownie wysłać wiadomość sms?' : 'Czy na pewno chcesz wysłać wiadomość sms?' }
                                        secondaryAction={ () => {} }
                                        secondaryText="Anuluj"
                                        primaryAction={ () => onSendSms(incorrectEmail?.id) }
                                        primaryText="Wyślij sms"
                                        displayIcon
                                        iconTitle="Wyślij sms"
                                        icon={ <SmsIcon/> }/>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default IncorrectEmailsList;
