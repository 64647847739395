import { deepFreeze } from '../../utils/misc_utils';

export const IncorrectEmailStatusEnum = deepFreeze({
    UNKNOWN: {
        value: 'UNKNOWN',
        label: 'Błąd'
    },
    CREATED: {
        value: 'CREATED',
        label: 'Utworzone'
    },
    UPDATED_EMAIL: {
        value: 'UPDATED_EMAIL',
        label: 'Zaktualizowano email'
    },
});
