import { deepFreeze } from '../../utils/misc_utils';

export const ReservationStoreStatusEnum = deepFreeze({
    UNKNOWN: {
        value: 'UNKNOWN',
        label: 'Błąd'
    },
    READY_FOR_REALIZATION: {
        value: 'READY_FOR_REALIZATION',
        label: 'Gotowe do realizacji'
    },
    ERROR_IN_RESERVATION: {
        value: 'ERROR_IN_RESERVATION',
        label: 'Błąd w zamówieniu'
    },
    IN_PROGRESS: {
        value: 'IN_PROGRESS',
        label: 'W trakcie realizacji'
    },
    COMPLETED: {
        value: 'COMPLETED',
        label: 'Zrealizowane'
    }
});

export const ReservationStatusEnum = deepFreeze({
    UNKNOWN: {
        value: 'UNKNOWN',
        label: 'Błąd'
    },
    NEW: {
        value: 'NEW',
        label: 'Nowe'
    },
    CANCELED_BY_STORE: {
        value: 'CANCELED_BY_STORE',
        label: 'Anulowane (przez dostawce)'
    },
    CANCELED_BY_CUSTOMER: {
        value: 'CANCELED_BY_CUSTOMER',
        label: 'Anulowane (przez użytkownika)'
    },
    WAITING_FOR_PAYMENT: {
        value: 'WAITING_FOR_PAYMENT',
        label: 'Oczekuje płatności'
    },
    PAID: {
        value: 'PAID',
        label: 'Opłacone'
    },
    UNPAID: {
        value: 'UNPAID',
        label: 'Nieopłacone'
    },
    DELIVERED: {
        value: 'DELIVERED',
        label: 'Dostarczone'
    },
    CONFIRMED: {
        value: 'CONFIRMED',
        label: 'Potwierdzone'
    },
    READY_TO_PICK_UP: {
        value: 'READY_TO_PICK_UP',
        label: 'Gotowe do odbioru'
    },
    PARTIALLY_OUTDATED: {
        value: 'PARTIALLY_OUTDATED',
        label: 'Częściowo niewykorzystane'
    },
    OUTDATED: {
        value: 'OUTDATED',
        label: 'Niewykorzystane'
    },
    PARTIALLY_FINISHED: {
        value: 'PARTIALLY_FINISHED',
        label: 'Częściowo zrealizowane'
    },
    FINISHED: {
        value: 'FINISHED',
        label: 'Zrealizowane'
    }
});

export const ReservationPaymentTypeEnum = deepFreeze({
    PAYMENT_ON_PICKUP: {
        value: 'PAYMENT_ON_PICKUP',
        label: 'Przy odbiorze'
    },
    PAYMENT_IN_ADVANCE: {
        value: 'PAYMENT_IN_ADVANCE',
        label: 'Z góry'
    }
});
