import React, { useState } from 'react';

import { Grid, TextField } from '@mui/material';
import _ from 'lodash';
import deepmerge from 'deepmerge';

import SelectField from '../../common/select_field';
import { getTheftRegistryOptions } from '../../common/utils/theft_registry_utils';
import { TheftRegistryStatusEnum } from '../../../core/enums/order/theft_registry';

const TheftRegistriesFilters = ({ queryParams, setQueryParams }) => {
    const [theftRegistryStatus, setTheftRegistryStatus] = useState(queryParams?.status || TheftRegistryStatusEnum.IN_REALIZATION.value);

    const onSelectTheftRegistryStatusChange = (event) => {
        setTheftRegistryStatus(event.target.value);
        setQueryParams(prevState => deepmerge(prevState, { status: event.target.value }));
    };

    const onSearchInputChangeDebounced = _.debounce((value) => onSearchInputChange(value), 600);

    const onSearchInputChange = (query) => {
        setQueryParams(prevState => deepmerge(prevState, { invoiceNumber: query }));
    };

    return (
        <Grid container gap={ 2 }>
            <Grid item xs={ 3 }>
                <SelectField
                    label="Status"
                    value={ theftRegistryStatus }
                    onChange={ onSelectTheftRegistryStatusChange }
                    options={ getTheftRegistryOptions }
                />
            </Grid>
            <Grid item xs={ 4 }>
                <TextField
                    label="Szukaj po numerze faktury"
                    fullWidth
                    placeholder={ queryParams?.invoiceNumber }
                    onChange={ event => onSearchInputChangeDebounced(event.target.value) }
                    InputLabelProps={ queryParams?.invoiceNumber ? { shrink: true } : undefined }
                />
            </Grid>
        </Grid>
    );
};
export default TheftRegistriesFilters;
