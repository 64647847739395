import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import SignedOutLayout from '../view/layout/signed_out_layout';
import SignInPage from '../view/main/sign_in';
import {
    ROUTE_ADVERTISEMENTS,
    ROUTE_AUTOMATIC_COUPON_SETTING_FORMS,
    ROUTE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING_FORMS,
    ROUTE_COUPON_FORMS,
    ROUTE_COUPON_FORMS_NEW,
    ROUTE_COUPONS,
    ROUTE_CUSTOMER,
    ROUTE_CUSTOMER_THEFT_REGISTRY_NEW,
    ROUTE_CUSTOMER_THEFT_REGISTRY_VIEW,
    ROUTE_CUSTOMERS,
    ROUTE_DELIVERY_NOTIFICATIONS,
    ROUTE_DELIVERY_NOTIFICATIONS_EDIT,
    ROUTE_DELIVERY_NOTIFICATIONS_NEW,
    ROUTE_EMPLOYER,
    ROUTE_EMPLOYERS,
    ROUTE_EXPIRATION_PROMOTIONS,
    ROUTE_EXPIRATION_PROMOTIONS_DETAILS,
    ROUTE_FOOD_PARTNER_FORMS,
    ROUTE_FOOD_PARTNER_FORMS_NEW,
    ROUTE_FOOD_PARTNER_ORDERS,
    ROUTE_FOOD_PARTNER_ORDERS_EDIT,
    ROUTE_FOOD_PARTNER_ORDERS_NEW,
    ROUTE_FOOD_PARTNER_ORDERS_VIEW,
    ROUTE_FOOD_PARTNERS,
    ROUTE_INCORRECT_EMAILS,
    ROUTE_INDOOR_PARTNER_EDIT,
    ROUTE_INDOOR_PARTNER_NEW,
    ROUTE_NOTIFICATIONS,
    ROUTE_NOTIFICATIONS_NEW,
    ROUTE_POD_ADVERTISEMENTS,
    ROUTE_POD_SALES,
    ROUTE_PODS,
    ROUTE_PODS_EDIT,
    ROUTE_PODS_NEW,
    ROUTE_PRICES,
    ROUTE_PRODUCER_FORMS,
    ROUTE_PRODUCER_FORMS_NEW,
    ROUTE_PRODUCERS,
    ROUTE_PRODUCT_FORMS,
    ROUTE_PRODUCT_PROMOTIONS,
    ROUTE_PRODUCT_PROMOTIONS_DETAILS,
    ROUTE_PRODUCTS,
    ROUTE_PROMOTION_CAMPAIGNS,
    ROUTE_PROMOTION_CAMPAIGNS_FORMS,
    ROUTE_PROMOTION_CAMPAIGNS_FORMS_NEW,
    ROUTE_RESERVATIONS,
    ROUTE_SALES,
    ROUTE_SIGN_IN,
    ROUTE_SUPPLIERS,
    ROUTE_SUPPLIERS_EDIT,
    ROUTE_SUPPLIERS_NEW,
    ROUTE_THEFT_REGISTRIES,
    ROUTE_WAREHOUSE_DOCUMENT_FORM,
    ROUTE_WAREHOUSE_DOCUMENT_VIEW,
    ROUTE_WAREHOUSE_DOCUMENTS,
    ROUTE_WAREHOUSE_REPORTS
} from './constants';
import { isSignedIn } from './utils/session_utils';
import { getTokenState } from '../features/authorization/get_token';
import SignedInLayout from '../view/layout/signed_in_layout';
import ProductsPage from '../view/main/products';
import ProductFormsPage from '../view/main/products/product_forms';
import ExpirationPromotionsDetailsPage from '../view/main/promotions/expiration_promotions/details';
import NotificationsPage from '../view/main/notifications';
import PricesPage from '../view/main/prices';
import SalesPage from '../view/main/sales';
import PodSalesPage from '../view/main/sales/pod_sales';
import CouponsPage from '../view/main/coupons';
import CouponFormsPage from '../view/main/coupons/coupon_forms';
import NotificationPage from '../view/main/notifications/form';
import AutomaticCouponSettingFormsPage from '../view/main/coupons/automatic_coupon_setting_forms';
import AdvertisementsPage from '../view/main/advertisments';
import AdvertisementDisplayOrderForm from '../view/main/advertisments/advertisement_forms';
import FoodPartnersPage from '../view/main/food_partners';
import FoodPartnerPage from '../view/main/food_partners/form';
import EmployersPage from '../view/main/employers';
import EmployerPage from '../view/main/employers/employer';
import ReservationsPage from '../view/main/reservations';
import WarehouseDocumentsPage from '../view/main/warehouse/documents';
import WarehouseDocumentDetailsPage from '../view/main/warehouse/documents/details';
import GenerateWarehouseDocumentForm from '../view/main/warehouse/documents/generate_forms';
import FoodPartnerOrdersPage from '../view/main/warehouse/food_partner_orders';
import FoodPartnerOrderCreateForm from '../view/main/warehouse/food_partner_orders/form/create';
import FoodPartnerOrderEditForm from '../view/main/warehouse/food_partner_orders/form/edit';
import AutomaticExpirationPromotionSettingFormsPage from '../view/main/promotions/expiration_promotions/form/automatic_expiration_promotion_setting_forms';
import ProducersPage from '../view/main/producers';
import ProducerPage from '../view/main/producers/form';
import PromotionCampaignFormsPage from '../view/main/promotions/promotion_campaigns/forms';
import SuppliersPage from '../view/main/suppliers';
import SupplierForm from '../view/main/suppliers/form';
import DeliveryNotificationsPage from '../view/main/delivery_notifications';
import DeliveryNotificationForm from '../view/main/delivery_notifications/form';
import ProductPromotionsPage from '../view/main/promotions/product_promotions';
import ProductPromotionsDetailsPage from '../view/main/promotions/product_promotions/details';
import ExpirationPromotionsPage from '../view/main/promotions/expiration_promotions';
import PromotionCampaignsPage from '../view/main/promotions/promotion_campaigns';
import WarehouseReportsPage from '../view/main/warehouse/reports';
import PodsPage from '../view/main/pods';
import PodForm from '../view/main/pods/form/pod_form';
import IndoorPartnerForm from '../view/main/pods/form/indoor_partner_form';
import CustomersPage from '../view/main/customers';
import CustomerPage from '../view/main/customers/customer';
import TheftRegistryForm from '../view/main/customers/customer/theft_tegistry/create';
import TheftRegistryDetailsPage from '../view/main/customers/customer/theft_tegistry/details';
import TheftRegistriesPage from '../view/main/theft_registries';
import IncorrectEmailsPage from '../view/main/incorrect_emails';

const Routing = () => {
    const getToken = useSelector(getTokenState);

    const signedOutRoutes = () => {
        return (
            <SignedOutLayout>
                <Routes>
                    <Route exact path={ ROUTE_SIGN_IN } element={ <SignInPage/> }/>
                    <Route exact path={ '*' } element={ <Navigate to={ ROUTE_SIGN_IN } replace/> }/>
                </Routes>
            </SignedOutLayout>
        );
    };

    const signedInRoutes = () => {
        return (
            <SignedInLayout>
                <Routes>
                    <Route exact path={ ROUTE_SIGN_IN } element={ <Navigate to={ ROUTE_PRODUCTS } replace/> }/>
                    <Route exact path={ ROUTE_PRODUCTS } element={ <ProductsPage/> }/>
                    <Route exact path={ ROUTE_PRODUCT_FORMS } element={ <ProductFormsPage/> }/>
                    <Route exact path={ ROUTE_PODS } element={ <PodsPage/> }/>
                    <Route exact path={ ROUTE_PODS_NEW } element={ <PodForm/> }/>
                    <Route exact path={ ROUTE_PODS_EDIT } element={ <PodForm/> }/>
                    <Route exact path={ ROUTE_INDOOR_PARTNER_NEW } element={ <IndoorPartnerForm/> }/>
                    <Route exact path={ ROUTE_INDOOR_PARTNER_EDIT } element={ <IndoorPartnerForm/> }/>
                    <Route exact path={ ROUTE_PRODUCERS } element={ <ProducersPage/> }/>
                    <Route exact path={ ROUTE_PRODUCER_FORMS } element={ <ProducerPage/> }/>
                    <Route exact path={ ROUTE_PRODUCER_FORMS_NEW } element={ <ProducerPage/> }/>
                    <Route exact path={ ROUTE_FOOD_PARTNERS } element={ <FoodPartnersPage/> }/>
                    <Route exact path={ ROUTE_FOOD_PARTNER_FORMS } element={ <FoodPartnerPage/> }/>
                    <Route exact path={ ROUTE_FOOD_PARTNER_FORMS_NEW } element={ <FoodPartnerPage/> }/>
                    <Route exact path={ ROUTE_EMPLOYERS } element={ <EmployersPage/> }/>
                    <Route exact path={ ROUTE_EMPLOYER } element={ <EmployerPage/> }/>
                    <Route exact path={ ROUTE_SUPPLIERS } element={ <SuppliersPage/> }/>
                    <Route exact path={ ROUTE_SUPPLIERS_NEW } element={ <SupplierForm/> }/>
                    <Route exact path={ ROUTE_SUPPLIERS_EDIT } element={ <SupplierForm/> }/>
                    <Route exact path={ ROUTE_CUSTOMERS } element={ <CustomersPage/> }/>
                    <Route exact path={ ROUTE_CUSTOMER } element={ <CustomerPage/> }/>
                    <Route exact path={ ROUTE_THEFT_REGISTRIES } element={ <TheftRegistriesPage/> }/>
                    <Route exact path={ ROUTE_CUSTOMER_THEFT_REGISTRY_NEW } element={ <TheftRegistryForm/> }/>
                    <Route exact path={ ROUTE_CUSTOMER_THEFT_REGISTRY_VIEW } element={ <TheftRegistryDetailsPage/> }/>
                    <Route exact path={ ROUTE_INCORRECT_EMAILS } element={ <IncorrectEmailsPage/> }/>
                    <Route exact path={ ROUTE_SALES } element={ <SalesPage/> }/>
                    <Route exact path={ ROUTE_POD_SALES } element={ <PodSalesPage/> }/>
                    <Route exact path={ ROUTE_RESERVATIONS } element={ <ReservationsPage/> }/>
                    <Route exact path={ ROUTE_PRODUCT_PROMOTIONS } element={ <ProductPromotionsPage/> }/>
                    <Route exact path={ ROUTE_PRODUCT_PROMOTIONS_DETAILS } element={ <ProductPromotionsDetailsPage/> }/>
                    <Route exact path={ ROUTE_EXPIRATION_PROMOTIONS } element={ <ExpirationPromotionsPage/> }/>
                    <Route exact path={ ROUTE_EXPIRATION_PROMOTIONS_DETAILS } element={ <ExpirationPromotionsDetailsPage/> }/>
                    <Route exact path={ ROUTE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING_FORMS } element={ <AutomaticExpirationPromotionSettingFormsPage/> }/>
                    <Route exact path={ ROUTE_PROMOTION_CAMPAIGNS } element={ <PromotionCampaignsPage/> }/>
                    <Route exact path={ ROUTE_PROMOTION_CAMPAIGNS_FORMS } element={ <PromotionCampaignFormsPage/> }/>
                    <Route exact path={ ROUTE_PROMOTION_CAMPAIGNS_FORMS_NEW } element={ <PromotionCampaignFormsPage/> }/>
                    <Route exact path={ ROUTE_NOTIFICATIONS } element={ <NotificationsPage/> }/>
                    <Route exact path={ ROUTE_NOTIFICATIONS_NEW } element={ <NotificationPage/> }/>
                    <Route exact path={ ROUTE_PRICES } element={ <PricesPage/> }/>
                    <Route exact path={ ROUTE_COUPONS } element={ <CouponsPage/> }/>
                    <Route exact path={ ROUTE_COUPON_FORMS } element={ <CouponFormsPage/> }/>
                    <Route exact path={ ROUTE_COUPON_FORMS_NEW } element={ <CouponFormsPage/> }/>
                    <Route exact path={ ROUTE_AUTOMATIC_COUPON_SETTING_FORMS } element={ <AutomaticCouponSettingFormsPage/> }/>
                    <Route exact path={ ROUTE_ADVERTISEMENTS } element={ <AdvertisementsPage/> }/>
                    <Route exact path={ ROUTE_POD_ADVERTISEMENTS } element={ <AdvertisementDisplayOrderForm/> }/>
                    <Route exact path={ ROUTE_WAREHOUSE_DOCUMENTS } element={ <WarehouseDocumentsPage/> }/>
                    <Route exact path={ ROUTE_WAREHOUSE_DOCUMENT_VIEW } element={ <WarehouseDocumentDetailsPage/> }/>
                    <Route exact path={ ROUTE_WAREHOUSE_DOCUMENT_FORM } element={ <GenerateWarehouseDocumentForm/> }/>
                    <Route exact path={ ROUTE_FOOD_PARTNER_ORDERS } element={ <FoodPartnerOrdersPage/> }/>
                    <Route exact path={ ROUTE_FOOD_PARTNER_ORDERS_NEW } element={ <FoodPartnerOrderCreateForm/> }/>
                    <Route exact path={ ROUTE_FOOD_PARTNER_ORDERS_EDIT } element={ <FoodPartnerOrderEditForm/> }/>
                    <Route exact path={ ROUTE_FOOD_PARTNER_ORDERS_VIEW } element={ <FoodPartnerOrderEditForm/> }/>
                    <Route exact path={ ROUTE_WAREHOUSE_REPORTS } element={ <WarehouseReportsPage/> }/>
                    <Route exact path={ ROUTE_DELIVERY_NOTIFICATIONS } element={ <DeliveryNotificationsPage/> }/>
                    <Route exact path={ ROUTE_DELIVERY_NOTIFICATIONS_NEW } element={ <DeliveryNotificationForm/> }/>
                    <Route exact path={ ROUTE_DELIVERY_NOTIFICATIONS_EDIT } element={ <DeliveryNotificationForm/> }/>
                    <Route exact path={ '*' } element={ <Navigate to={ ROUTE_SIGN_IN } replace/> }/>
                </Routes>
            </SignedInLayout>
        );
    };

    return (
        <BrowserRouter>
            <div>
                {
                    isSignedIn(getToken)
                        ? signedInRoutes()
                        : signedOutRoutes()
                }
            </div>
        </BrowserRouter>
    );
};

export default Routing;
