import { deepFreeze } from '../../utils/misc_utils';

export const CustomerSuccessMessageEnum = deepFreeze({
    CREATE_INCORRECT_EMAIL: 'Pomyślnie dodano nowy adres email',
    CREATE_INCORRECT_EMAIL_SMS: 'Pomyślnie wysłano wiadomość sms',
});

export const CustomerErrorMessageEnum = deepFreeze({
    GET_CUSTOMERS_BY_PHONE_NUMBER: 'Pobranie klientów nie powiodło się',
    GET_CUSTOMERS: 'Pobranie klientów nie powiodło się',
    GET_CUSTOMER: 'Pobranie klienta nie powiodło się',
    GET_CUSTOMERS_BY_IDS: 'Pobranie klientów nie powiodło się',
    GET_CUSTOMERS_ACTIVITIES: 'Pobranie akcji klienta nie powiodło się',
    UPDATE_CUSTOMER_STATUS: 'Aktualizacja statusu klienta nie powiodła się',
    GET_INCORRECT_EMAILS: 'Pobranie nieprawidłowych adresów email nie powiodło się',
    CREATE_INCORRECT_EMAIL: 'Dodanie nowego adresu email nie powiodło się',
    CREATE_INCORRECT_EMAIL_SMS: 'Wysłanie wiadomości sms nie powiodło się',
});

const CustomerServerErrorMessageEnum = deepFreeze({
    CUSTOMERS_WITH_GIVEN_EMAIL_NOT_FOUND: {
        message: 'Nie znaleziono klientów z podanym adresem email',
        errorCode: '400.30'
    }
});

export const getCustomerErrorMessage = (message, defaultErrorMessage) => {
    for (let key in CustomerServerErrorMessageEnum) {
        if (CustomerServerErrorMessageEnum.hasOwnProperty(key)) {
            if (message?.substring(0, message?.indexOf(' ')) === CustomerServerErrorMessageEnum[key].errorCode) {
                return CustomerServerErrorMessageEnum[key].message;
            }
        }
    }

    return defaultErrorMessage;
};
