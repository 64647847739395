import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';

import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import { Button } from '../../../../common/button';
import ReservationStoreStatusFormSelect from './form/reservation_store_status_form_select';
import { ReservationPaymentTypeEnum } from '../../../../../core/enums/reservation/reservation';

const ReservationModal = ({ reservation, refreshReservations }) => {
    const [open, setOpen] = useState(false);

    const getAllPods = useSelector(getAllPodsState);

    const onClose = () => setOpen(false);

    return (
        <>
            <SearchIcon onClick={ () => setOpen(true) } cursor="pointer"/>
            <Dialog open={ open } onClose={ onClose } fullWidth maxWidth={ 'lg' }>
                <DialogTitle>
                    Numer rezerwacji: { reservation?.reservationId }
                </DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Grid container rowGap={ 4 }>
                        <Grid container item>
                            <Grid container item>
                                <Typography>
                                    Imię i nazwisko Smakosza:
                                    &nbsp;
                                    { (reservation?.customer?.name || 'Brak') + ' ' + (reservation?.customer?.surname || 'Brak') }
                                </Typography>
                            </Grid>
                            <Grid container item>
                                <Typography>
                                    Nr telefonu:
                                    &nbsp;
                                    { reservation?.customer?.phonePrefix + reservation?.customer?.phoneNumber }
                                </Typography>
                            </Grid>
                            <Grid container item>
                                <Typography>
                                    Data rezerwacji:
                                    &nbsp;
                                    { reservation?.date }
                                </Typography>
                            </Grid>
                            <Grid container item>
                                <Typography>
                                    Status:
                                    &nbsp;
                                    <b>{ reservation?.displayStatusValue }</b>
                                </Typography>
                            </Grid>
                            <Grid container item>
                                <Typography>
                                    Punkt Sprzedaży:
                                    &nbsp;
                                    {
                                        getAllPods?.data?.map(pod => ({ id: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') }))
                                            .find(pod => pod?.id === reservation?.podId)?.label
                                    }
                                </Typography>
                            </Grid>
                            {
                                reservation?.paymentType === ReservationPaymentTypeEnum.PAYMENT_IN_ADVANCE.value &&
                                <>
                                    <br/>
                                    <br/>
                                    <Grid container item>
                                        <Typography>
                                            Numer zamówienia:
                                            &nbsp;
                                            { reservation?.reservationOrderId }
                                        </Typography>
                                    </Grid>
                                    <Grid container item>
                                        <Typography>
                                            Numer faktury:
                                            &nbsp;
                                            <b>{ reservation?.invoiceNumber || '-'}</b>
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            <Grid container item xs={ 4 }>
                                <ReservationStoreStatusFormSelect
                                    reservationId={ reservation?.reservationId }
                                    storeStatus={ reservation?.storeStatus }
                                    refreshReservations={ refreshReservations }/>
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nazwa produktu</TableCell>
                                            <TableCell align="center">Ilość</TableCell>
                                            <TableCell>Półka</TableCell>
                                            <TableCell align="center">Cena brutto</TableCell>
                                            <TableCell align="center">Vat [%]</TableCell>
                                            <TableCell align="center">Waluta</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            reservation?.items?.map(item => (
                                                <TableRow
                                                    key={ reservation?.reservationId }
                                                    hover
                                                >
                                                    <TableCell>{ item?.product?.productName }</TableCell>
                                                    <TableCell align="center">{ item?.quantity }</TableCell>
                                                    <TableCell>{ item?.segmentName }</TableCell>
                                                    <TableCell align="center">{ item?.grossPrice?.toFixed(2) }</TableCell>
                                                    <TableCell align="center">{ item?.vat }</TableCell>
                                                    <TableCell align="center">{ item?.currency }</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <DialogActions>
                                <Button onClick={ onClose }>Zamknij</Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ReservationModal;
