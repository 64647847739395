import React, { useState } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import SearchIcon from '@mui/icons-material/Search';
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';
import moment from 'moment';

import { Button, CustomIconButton } from '../../../../../common/button';
import { MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_DATE_ONLY_FORMAT } from '../../../../../../core/constants';

const DetailsDialog = ({ product }) => {
    const [open, setOpen] = useState(false);

    const onClose = () => setOpen(false);

    return (
        <>
            <CustomIconButton
                title={ 'Szczegóły' }
                onClick={ () => setOpen(true) }
                icon={ <SearchIcon/> }/>
            <Dialog open={ open } onClose={ onClose } fullWidth maxWidth={ 'lg' }>
                <DialogTitle>{ product?.name || product?.productName }</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {
                                        product?.expirationDate &&
                                        <TableCell align="center">Data przydatności</TableCell>
                                    }
                                    <TableCell align="center">Cena podstawowa</TableCell>
                                    <TableCell align="center">Promocja</TableCell>
                                    <TableCell align="center">Rabat kwotowy - promocja</TableCell>
                                    <TableCell align="center">Promocja - krótki termin</TableCell>
                                    <TableCell align="center">Rabat kwotowy - krótki termin</TableCell>
                                    {
                                        product?.promotionGrossPrice &&
                                        <TableCell align="center">Cena promocyjna</TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    {
                                        product?.expirationDate &&
                                        <TableCell align="center">
                                            {
                                                moment(product?.expirationDate, MISC_DATE_ISO_FORMAT)
                                                    .format(MISC_DATE_POLISH_DATE_ONLY_FORMAT)

                                            }
                                        </TableCell>
                                    }
                                    <TableCell align="center">
                                        {
                                            product?.basicPrice?.toFixed(2)
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            product?.productPromotionId
                                                ? <CheckIcon color="success"/>
                                                : <ClearIcon color="error"/>
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            product?.productDiscountAmount?.toFixed(2)
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            product?.expirationPromotionId
                                                ? <CheckIcon color="success"/>
                                                : <ClearIcon color="error"/>
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            product?.expirationDiscountAmount?.toFixed(2)
                                        }
                                    </TableCell>
                                    {
                                        product?.promotionGrossPrice &&
                                        <TableCell align="center">
                                            {
                                                product?.promotionGrossPrice?.toFixed(2)
                                            }
                                        </TableCell>
                                    }
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ onClose }>Zamknij</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DetailsDialog;
