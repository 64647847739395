import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment/moment';

import ThreeLevelSteps from '../../../common/steps';
import AdditionalOptions from './common/additional_options';
import { isEmptyObject } from '../../../../../core/utils/misc_utils';
import useNotification from '../../../../../core/hooks/use_notification';
import DestinationsList from './common/destinations_list';
import AddDestinationDialog from '../../../common/warehouse_and_food_partner_order_form/add_destination_dialog';
import { getFoodPartnerNotificationData } from '../../../../../core/utils/notification_utils';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from '../../../../../core/constants';
import { getFoodPartnerAction } from '../../../../../features/food_partner/get_food_partner';
import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import Buttons from '../../../common/buttons';
import UploadFiles from '../../../common/warehouse_and_food_partner_order_form/upload_files';

const PZAndMMForm = ({ form, setView, data, setData }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getAllPods = useSelector(getAllPodsState);

    useEffect(() => {
        dispatch(getFoodPartnerAction(form?.foodPartnerId))
            .then(response => showErrorMessage(getFoodPartnerNotificationData(response)));
    }, [dispatch, form, showErrorMessage]);

    const initialValues = {
        destinationsList: data?.formik?.destinationsList || [],
        productsList: data?.formik?.productsList || [],
        attachments: data?.formik?.attachments || [],
    };

    const onReturn = () => {
        setData({});
        setView(0);
    };

    const onChangeSetDate = (newDate, allDates, formik) => {
        formik?.values?.destinationsList?.forEach((_, index) => {
            const productsList = formik?.values?.productsList[index]?.map(product => {
                if (!allDates && product?.expirationDate) {
                    return product;
                }

                return ({ ...product, expirationDate: newDate });
            });

            if (productsList?.length !== 0) {
                formik.setFieldValue(`productsList.${ index }`, productsList);
            }
        });
    };

    const onCopyHistoricalDocument = (values, formik) => {
        const destinationsList = [];
        const productsList = [];

        if (!getAllPods?.data) return;

        Object.keys(values)?.forEach((key, index) => {
            destinationsList.push({
                position: index,
                name: getAllPods?.data?.map(pod => ({ id: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') })).find(pod => pod?.id === key)?.label,
                destinationPodId: key
            });

            productsList[index] = values?.[key]?.map((product, index) => ({
                position: index,
                name: product?.productName,
                productId: product?.productId,
                productBatchId: '',
                quantity: product?.quantity,
                expirationDate: product?.defaultDaysToExpiration ? moment(form?.deliveryDate).add(product?.defaultDaysToExpiration - 1, 'days') : '',
                price: product?.purchaseNetPrice || '',
                type: product?.type
            }));
        });

        formik.setFieldValue('destinationsList', destinationsList);
        formik.setFieldValue('productsList', productsList);
    };

    const onSubmit = (formik) => {
        const errors = validate(formik.values);

        if (errors.productsList.length ||
            errors.products.length ||
            !isEmptyObject(errors.destinationsList)) {
            formik.setErrors(errors);
            return;
        }

        const documents = [
            ...formik.values.destinationsList.map((destination, index) => (
                {
                    destinationPodId: destination.destinationPodId,
                    documentProducts: [
                        ...formik?.values?.productsList?.[index]?.map(product => ({
                            productId: product?.productId,
                            quantity: product?.quantity,
                            expirationDate: product?.expirationDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
                            price: product?.price
                        }))
                    ]
                }
            ))
        ];

        setData({
            form: { ...form, deliveryDate: form?.deliveryDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT), documents, attachments: formik?.values?.attachments || [] },
            formik: formik?.values
        });
        setView(5);
    };

    const validate = (values) => {
        const errors = {
            productsList: [],
            products: [],
            destinationsList: {}
        };

        if (values.destinationsList.length === 0) {
            errors.destinationsList = 'Wybierz minimum 1 lokalizację';
        } else {
            values.destinationsList.forEach((_, index) => {
                if (values.productsList[index] === undefined || values.productsList[index].length === 0) {
                    errors.productsList[index] = 'Wybierz minimum 1 produkt';
                } else {
                    values.productsList[index].forEach((product, productIndex) => {
                        if (!product?.quantity) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [productIndex]: {
                                    ...errors.products?.[index]?.[productIndex],
                                    quantity: 'Pole wymagane'
                                }
                            };
                        } else {
                            if (product?.quantity <= 0) {
                                const oldValues = errors.products[index];
                                errors.products[index] = {
                                    ...oldValues,
                                    [productIndex]: {
                                        ...errors.products?.[index]?.[productIndex],
                                        quantity: 'Proszę podać wartość całkowitą dodatnią'
                                    }
                                };
                            }
                        }

                        if (!product?.expirationDate) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [productIndex]: {
                                    ...errors.products?.[index]?.[productIndex],
                                    expirationDate: 'Pole wymagane'
                                }
                            };
                        }

                        if (!product?.price) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [productIndex]: {
                                    ...errors.products?.[index]?.[productIndex],
                                    price: 'Pole wymagane'
                                }
                            };
                        } else {
                            if (product?.price <= 0) {
                                const oldValues = errors.products[index];
                                errors.products[index] = {
                                    ...oldValues,
                                    [productIndex]: {
                                        ...errors.products?.[index]?.[productIndex],
                                        price: 'Proszę podać wartość dodatnią'
                                    }
                                };
                            }
                        }
                    });
                }
            });
        }

        return errors;
    };

    return (
        <Formik initialValues={ initialValues }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 2 } paddingTop={ 2 }>
                    <ThreeLevelSteps secondStep/>
                    <AdditionalOptions
                        onChangeSetDate={ (newDate, allDates) => onChangeSetDate(newDate, allDates, formik) }
                        form={ form }
                        onCopyHistoricalDocument={ (values) => onCopyHistoricalDocument(values, formik) }/>
                    <DestinationsList
                        form={ form }
                        formik={ formik }/>
                    <AddDestinationDialog
                        formik={ formik }/>
                    <UploadFiles formik={ formik } addAndRemoveOptions/>
                    <Buttons
                        onReturn={ onReturn }
                        onSubmit={ () => onSubmit(formik) }
                    />
                </Grid>
            }
        </Formik>
    );
};

export default PZAndMMForm;
