import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import deepmerge from 'deepmerge';

import useNotification from '../../../core/hooks/use_notification';
import { getCustomersNotificationData } from '../../../core/utils/notification_utils';
import { getDefaultPaginationByIdQueryValues } from '../../../core/utils/api_utils';
import PaginationControls from '../../common/pagination_controls';
import { getCustomersAction, getCustomersState } from '../../../features/customer/get_customers';
import CustomersList from './customers_list';
import CustomerFilters from './customer_filters';

const initialQueryParams = {
    ...getDefaultPaginationByIdQueryValues,
    status: '',
    queryFilter: '',
    queryValue: ''
};

const CustomersPage = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const location = useLocation();

    const getCustomers = useSelector(getCustomersState);

    const [queryParams, setQueryParams] = useState(location?.state?.queryParams || initialQueryParams);

    const refreshList = useCallback(() => {
        if (queryParams?.queryFilter && !queryParams?.queryValue) return;

        dispatch(getCustomersAction(queryParams))
            .then(response => showErrorMessage(getCustomersNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [refreshList]);

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } rowGap={ 4 } justifyContent="space-between">
                <Grid container>
                    <Typography variant="h1">Użytkownicy</Typography>
                </Grid>
                <Grid container>
                    <CustomerFilters
                        queryParams={ queryParams }
                        setQueryParams={ setQueryParams }/>
                </Grid>
            </Grid>
            {
                getCustomers?.loading &&
                <LinearProgress/>
            }
            {
                getCustomers?.data?.content &&
                <>
                    <CustomersList queryParams={ queryParams }/>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getCustomers?.data?.pageable?.pageNumber }
                            totalPages={ getCustomers?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </>
            }
        </Grid>
    );
};

export default CustomersPage;
