import { deepFreeze } from '../../utils/misc_utils';

export const TheftRegistryStatusEnum = deepFreeze({
    UNKNOWN: {
        value: 'UNKNOWN',
        label: 'Błąd'
    },
    IN_REALIZATION: {
        value: 'IN_REALIZATION',
        label: 'W realizacji'
    },
    FINISHED: {
        value: 'FINISHED',
        label: 'Opłacono'
    },
});
