import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import { Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import DialogTitle from '@mui/material/DialogTitle';

import { Button } from '../../../common/button';
import useNotification from '../../../../core/hooks/use_notification';
import TextFieldFormik from '../../../common/text_field';
import { createIncorrectEmailNotificationData } from '../../../../core/utils/notification_utils';
import { createIncorrectEmailAction, createIncorrectEmailState } from '../../../../features/customer/create_incorrect_email';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';

const IncorrectEmailFormDialog = ({ refreshList }) => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();

    const createIncorrectEmail = useSelector(createIncorrectEmailState);

    const [open, setOpen] = useState(false);

    const initialValues = {
        incorrectEmail: '',
    };

    const schema = Yup.object().shape({
        incorrectEmail: Yup.string().required()
    });

    const onOpen = () => setOpen(true);

    const onClose = () => setOpen(false);

    const onSubmit = (values, sendSms) => {
        dispatch(createIncorrectEmailAction({ incorrectEmail: values?.incorrectEmail, sendSms }))
            .then(response => {
                showNotification(createIncorrectEmailNotificationData(response));

                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    refreshList();
                    onClose();
                }
            });
    };

    return (
        <>
            <Button onClick={ onOpen }>
                Dodaj nowy wpis
            </Button>
            <Dialog open={ open } onClose={ onClose } fullWidth={ true } maxWidth={ 'sm' }>
                <DialogTitle>Nowy wpis</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={ schema }
                        validateOnChange={ false }
                        validateOnBlur={ false }>
                        { (formik) =>
                            <Grid component={ Form } container rowGap={ 4 }>
                                <Grid container item>
                                    <TextFieldFormik name="incorrectEmail" label="Nieprawidłowy email" required/>
                                </Grid>
                                <Grid container item mt={ 2 } justifyContent="flex-end">
                                    <DialogActions sx={ { gap: 2 } }>
                                        <Button onClick={ onClose }>Zamknij</Button>
                                        <Button isLoading={ createIncorrectEmail?.loading } onClick={ () => onSubmit(formik?.values, false) }>Dodaj</Button>
                                        <Button isLoading={ createIncorrectEmail?.loading } onClick={ () => onSubmit(formik?.values, true) }>Dodaj i wyślij sms</Button>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default IncorrectEmailFormDialog;
