import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import _ from 'lodash';
import deepmerge from 'deepmerge';

import useNotification from '../../../core/hooks/use_notification';
import { getIncorrectEmailsNotificationData } from '../../../core/utils/notification_utils';
import SelectField from '../../common/select_field';
import PaginationControls from '../../common/pagination_controls';
import { getDefaultPaginationQueryValues } from '../../../core/utils/api_utils';
import { getIncorrectEmailsAction, getIncorrectEmailsState } from '../../../features/customer/get_incorrect_emails';
import { getIncorrectEmailStatusOptions } from '../../common/utils/incorrect_email';
import IncorrectEmailsList from './list';
import IncorrectEmailFormDialog from './form/incorrect_email_form_dialog';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    status: 'CREATED',
    sort: ['createdDate', 'desc']
};

const IncorrectEmailsPage = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getIncorrectEmails = useSelector(getIncorrectEmailsState);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const refreshList = useCallback(() => {
        dispatch(getIncorrectEmailsAction(queryParams))
            .then(response => showErrorMessage(getIncorrectEmailsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [refreshList]);

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } rowGap={ 4 } justifyContent="space-between">
                <Grid container>
                    <Typography variant="h1">Nieprawidłowe adresy email</Typography>
                </Grid>
                <Grid container>
                    <Grid item>
                        <SelectField
                            label={ 'Pokaż' }
                            value={ _.pick(queryParams, ['status']).status }
                            onChange={ (event) => setQueryParams(prevState => deepmerge(prevState, { status: event.target.value })) }
                            options={ getIncorrectEmailStatusOptions }
                        />
                    </Grid>
                </Grid>
                <IncorrectEmailFormDialog refreshList={ refreshList } />
            </Grid>
            {
                getIncorrectEmails?.loading &&
                <LinearProgress/>
            }
            {
                getIncorrectEmails?.data?.content &&
                <>
                    <IncorrectEmailsList/>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getIncorrectEmails?.data?.pageable?.pageNumber }
                            totalPages={ getIncorrectEmails?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </>
            }
        </Grid>
    );
};

export default IncorrectEmailsPage;
