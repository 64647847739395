import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import { WAREHOUSE_DOCUMENTS_PATH } from '../../core/constants';

export const generateReservationDocumentsAction = createAsyncThunk(
    'inventory/generateReservationDocumentsAction',
    async (date, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.post(`${ WAREHOUSE_DOCUMENTS_PATH }/reservations/generate?forDate=${ date }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const generateReservationDocumentsSlice = createSlice({
    name: 'generateReservationDocumentsSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(generateReservationDocumentsAction.pending, () => STATE__API__STARTED);
        builder.addCase(generateReservationDocumentsAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(generateReservationDocumentsAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const generateReservationDocumentsState = (state) => state.inventory.generateReservationDocuments;
export default generateReservationDocumentsSlice.reducer;
