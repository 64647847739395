import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import webClient from '../../core/web_client';
import { buildQueryParamValueArray, buildQueryString, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { RESERVATIONS_PATH } from '../../core/constants';

export const getReservationsAction = createAsyncThunk(
    'reservation/getReservationsAction',
    async (queryParams, { fulfillWithValue, rejectWithValue }) => {
        try {
            const reservationStatuses = buildQueryParamValueArray(_.pick(queryParams, ['reservationStatuses']).reservationStatuses);
            const podIds = buildQueryParamValueArray(_.pick(queryParams, ['podIds']).podIds);
            const response = await webClient.get(`${ RESERVATIONS_PATH }${ buildQueryString(_.omit(queryParams, ['reservationStatuses', 'podIds'])) }&reservationStatus=${ reservationStatuses }&podId=${ podIds }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getReservationsSlice = createSlice({
    name: 'getReservationsSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getReservationsAction.pending, () => STATE__API__STARTED);
        builder.addCase(getReservationsAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getReservationsAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getReservationsState = (state) => state.reservation.getReservations;
export default getReservationsSlice.reducer;
