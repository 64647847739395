import { ReservationPaymentTypeEnum, ReservationStatusEnum, ReservationStoreStatusEnum } from '../../../core/enums/reservation/reservation';

export const getReservationStoreStatusOptions = [
    { value: ReservationStoreStatusEnum.READY_FOR_REALIZATION.value, label: ReservationStoreStatusEnum.READY_FOR_REALIZATION.label },
    { value: ReservationStoreStatusEnum.ERROR_IN_RESERVATION.value, label: ReservationStoreStatusEnum.ERROR_IN_RESERVATION.label },
    { value: ReservationStoreStatusEnum.IN_PROGRESS.value, label: ReservationStoreStatusEnum.IN_PROGRESS.label },
    { value: ReservationStoreStatusEnum.COMPLETED.value, label: ReservationStoreStatusEnum.COMPLETED.label }
];

export const getReservationStatusOptions = [
    { value: ReservationStatusEnum.NEW.value, label: ReservationStatusEnum.NEW.label },
    { value: ReservationStatusEnum.CANCELED_BY_STORE.value, label: ReservationStatusEnum.CANCELED_BY_STORE.label },
    { value: ReservationStatusEnum.CANCELED_BY_CUSTOMER.value, label: ReservationStatusEnum.CANCELED_BY_CUSTOMER.label },
    { value: ReservationStatusEnum.WAITING_FOR_PAYMENT.value, label: ReservationStatusEnum.WAITING_FOR_PAYMENT.label },
    { value: ReservationStatusEnum.PAID.value, label: ReservationStatusEnum.PAID.label },
    { value: ReservationStatusEnum.UNPAID.value, label: ReservationStatusEnum.UNPAID.label },
    { value: ReservationStatusEnum.DELIVERED.value, label: ReservationStatusEnum.DELIVERED.label },
    { value: ReservationStatusEnum.CONFIRMED.value, label: ReservationStatusEnum.CONFIRMED.label },
    { value: ReservationStatusEnum.READY_TO_PICK_UP.value, label: ReservationStatusEnum.READY_TO_PICK_UP.label },
    { value: ReservationStatusEnum.PARTIALLY_OUTDATED.value, label: ReservationStatusEnum.PARTIALLY_OUTDATED.label },
    { value: ReservationStatusEnum.OUTDATED.value, label: ReservationStatusEnum.OUTDATED.label },
    { value: ReservationStatusEnum.PARTIALLY_FINISHED.value, label: ReservationStatusEnum.PARTIALLY_FINISHED.label },
    { value: ReservationStatusEnum.FINISHED.value, label: ReservationStatusEnum.FINISHED.label }
];

export const getReservationPaymentTypeOptions = [
    { value: ReservationPaymentTypeEnum.PAYMENT_ON_PICKUP.value, label: ReservationPaymentTypeEnum.PAYMENT_ON_PICKUP.label },
    { value: ReservationPaymentTypeEnum.PAYMENT_IN_ADVANCE.value, label: ReservationPaymentTypeEnum.PAYMENT_IN_ADVANCE.label }
]

export const getSupportedPaymentType = (paymentType) => {
    switch (paymentType) {
        case ReservationPaymentTypeEnum.PAYMENT_ON_PICKUP.value:
            return ReservationPaymentTypeEnum.PAYMENT_ON_PICKUP.label;
        case ReservationPaymentTypeEnum.PAYMENT_IN_ADVANCE.value:
            return ReservationPaymentTypeEnum.PAYMENT_IN_ADVANCE.label;
        default:
            return null;
    }
};