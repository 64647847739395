import React from 'react';

import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';

import ThreeLevelSteps from '../../../common/steps';
import { isEmptyObject } from '../../../../../core/utils/misc_utils';
import DestinationsList from './common/destinations_list';
import AddDestinationDialog from '../../../common/warehouse_and_food_partner_order_form/add_destination_dialog';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from '../../../../../core/constants';
import Buttons from '../../../common/buttons';

const MMForm = ({ form, setView, data, setData }) => {
    const initialValues = {
        destinationsList: data?.formik?.destinationsList || [],
        productsList: data?.formik?.productsList || [],
        productsForValidation: data?.formik?.productsForValidation || []
    };

    const onReturn = () => {
        setData({});
        setView(0);
    };

    const onSubmit = (formik) => {
        const errors = validate(formik.values);

        if (errors.productsList.length ||
            errors.products.length ||
            !isEmptyObject(errors.destinationsList)) {
            formik.setErrors(errors);

            return;
        }

        const documents = [
            ...formik.values.destinationsList.map((destination, index) => (
                {
                    targetWarehouseId: destination.warehouseId,
                    documentProducts: [
                        ...formik?.values?.productsList?.[index]?.map(product => ({
                            productId: product?.productId,
                            productBatchId: product?.productBatchId,
                            quantity: product?.quantity
                        }))
                    ]
                }
            ))
        ];

        setData({
            form: { ...form, deliveryDate: form?.deliveryDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT), documents },
            formik: formik?.values
        });
        setView(5);
    };

    const validate = (values) => {
        const errors = {
            productsList: [],
            products: [],
            destinationsList: {},
            quantityErrors: {}
        };

        if (values.destinationsList.length === 0) {
            errors.destinationsList = 'Wybierz minimum 1 lokalizację';
        } else {
            values.destinationsList.forEach((_, index) => {
                if (values.productsList[index] === undefined || values.productsList[index].length === 0) {
                    errors.productsList[index] = 'Wybierz minimum 1 produkt';
                } else {
                    values.productsList[index].forEach((product, productIndex) => {
                        if (!product?.quantity) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [productIndex]: {
                                    ...errors.products?.[index]?.[productIndex],
                                    quantity: 'Pole wymagane'
                                }
                            };
                        } else {
                            if (product?.quantity <= 0) {
                                const oldValues = errors.products[index];
                                errors.products[index] = {
                                    ...oldValues,
                                    [productIndex]: {
                                        ...errors.products?.[index]?.[productIndex],
                                        quantity: 'Proszę podać wartość całkowitą dodatnią'
                                    }
                                };
                            }
                        }
                    });
                }
            });

            if (errors.productsList.length || errors.products.length) return errors;

            values.destinationsList.forEach((_, index) => {
                values.productsList[index].forEach((product, productIndex) => {
                    const productForValidation = values?.productsForValidation?.find(pfv => (pfv?.product?.id === product?.productId) && (pfv?.productBatchId === product?.productBatchId));

                    if (errors.quantityErrors?.[product?.productBatchId]?.[product?.productId]) {
                        const oldValues = errors.products[index];
                        errors.products[index] = {
                            ...oldValues,
                            [productIndex]: {
                                ...errors.products?.[index]?.[productIndex],
                                quantity: errors.quantityErrors?.[product?.productBatchId]?.[product?.productId]
                            }
                        };

                        return;
                    }

                    const productsSum = values.productsList.flat()
                        .filter(p => (p?.productId === product?.productId) && (p?.productBatchId === product?.productBatchId))
                        .map(p => p.quantity)
                        .reduce((accumulator, current) => accumulator + current, 0);

                    if (productForValidation.quantity < productsSum) {
                        let oldValues = errors.products[index];
                        errors.products[index] = {
                            ...oldValues,
                            [productIndex]: {
                                ...errors.products?.[index]?.[productIndex],
                                quantity: `Dostępne ${ productForValidation.quantity } szt. na magazynie źródłowym`
                            }
                        };

                        oldValues = errors.quantityErrors[product?.productBatchId];
                        errors.quantityErrors[product?.productBatchId] = {
                            ...oldValues,
                            [product?.productId]: `Dostępne ${ productForValidation.quantity } szt. na magazynie źródłowym`
                        };
                    }
                });
            });
        }

        return errors;
    };

    return (
        <Formik initialValues={ initialValues }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 2 } paddingTop={ 2 }>
                    <ThreeLevelSteps secondStep/>
                    <DestinationsList
                        form={ form }
                        formik={ formik }
                        onlyDelivery/>
                    <AddDestinationDialog
                        formik={ formik }
                        onlyDelivery
                        sourceWarehouseId={ form?.sourceWarehouseId }/>
                    <Buttons
                        onReturn={ onReturn }
                        onSubmit={ () => onSubmit(formik) }
                    />
                </Grid>
            }
        </Formik>
    );
};

export default MMForm;
