import { CartEventActionEnum } from '../../../core/enums/order/order';

export const getCartEventAction = (action) => {
    switch (action) {
        case CartEventActionEnum.CREATE.value:
            return CartEventActionEnum.CREATE.label;
        case CartEventActionEnum.ADD_PRODUCT.value:
            return CartEventActionEnum.ADD_PRODUCT.label;
        case CartEventActionEnum.ADD_PRODUCT_FAIL.value:
            return CartEventActionEnum.ADD_PRODUCT_FAIL.label;
        case CartEventActionEnum.ADD_RESERVATION_PRODUCTS.value:
            return CartEventActionEnum.ADD_RESERVATION_PRODUCTS.label;
        case CartEventActionEnum.REMOVE_PRODUCT.value:
            return CartEventActionEnum.REMOVE_PRODUCT.label;
        case CartEventActionEnum.CHANGE_STATUS.value:
            return CartEventActionEnum.CHANGE_STATUS.label;
        case CartEventActionEnum.REFRESH.value:
            return CartEventActionEnum.REFRESH.label;
        case CartEventActionEnum.FINALIZE.value:
            return CartEventActionEnum.FINALIZE.label;
        default:
            return CartEventActionEnum.CLEAR.label;
    }
};
