import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { Box, Chip, Grid, LinearProgress, TextField, Typography } from '@mui/material';

import { generateWarehouseDocumentLabelsNotificationData, getPodsNotificationData, getWarehouseDocumentNotificationData } from '../../../../../core/utils/notification_utils';
import useNotification from '../../../../../core/hooks/use_notification';
import { getWarehouseDocumentAction, getWarehouseDocumentState } from '../../../../../features/inventory/get_warehouse_document';
import { Button } from '../../../../common/button';
import { MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_DATE_ONLY_FORMAT, ROUTE_WAREHOUSE_DOCUMENTS } from '../../../../../core/constants';
import { getAllPodsAction } from '../../../../../features/pod/get_all_pods';
import { getAllWarehousesState } from '../../../../../features/inventory/get_all_warehouses';
import { generateWarehouseDocumentLabelsAction, generateWarehouseDocumentLabelsState } from '../../../../../features/inventory/generate_warehouse_document_labels';
import { getWarehouseName } from '../../../../common/utils/warehouse_utils';
import DetailsForm from './form';
import DetailsList from './list';

const WarehouseDocumentDetailsPage = () => {
    const { showErrorMessage, showNotification } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { warehouseDocumentId } = useParams();
    const location = useLocation();

    const getWarehouseDocument = useSelector(getWarehouseDocumentState);
    const getAllWarehouses = useSelector(getAllWarehousesState);
    const generateWarehouseDocumentLabels = useSelector(generateWarehouseDocumentLabelsState);

    const isMWDocument = getWarehouseDocument?.data?.documentType === 'MW';
    const showForm = isMWDocument && !location?.state?.confirmed && getAllWarehouses?.data?.find(warehouse => warehouse?.internalId === getWarehouseDocument?.data?.targetWarehouseId)?.pod?.podId;

    useEffect(() => {
        warehouseDocumentId && dispatch(getWarehouseDocumentAction(warehouseDocumentId))
            .then(response => showErrorMessage((getWarehouseDocumentNotificationData(response))));
    }, [warehouseDocumentId, dispatch, showErrorMessage]);

    useEffect(() => {
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
    }, [dispatch, showErrorMessage]);

    const onGenerateWarehouseDocumentLabels = () => {
        dispatch(generateWarehouseDocumentLabelsAction({ id: warehouseDocumentId }))
            .then(response => {
                showNotification(generateWarehouseDocumentLabelsNotificationData(response));
            });
    };

    const renderView = () => {
        if (getWarehouseDocument?.loading) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        return (
            <Grid container sx={ { paddingTop: '40px !important' } } item gap={ 2 }>
                <Grid container gap={ 2 }>
                    <Typography variant="h1">{ getWarehouseDocument?.data?.name }</Typography>
                    {
                        getWarehouseDocument?.data?.purposeType === 'RESERVATION' &&
                        <Chip label="Rezerwacje użytkowników" color="success" variant="outlined"/>
                    }
                </Grid>
                <Grid container gap={ 2 }>
                    <Grid item>
                        <TextField fullWidth value={ moment(getWarehouseDocument?.data?.issueDate, MISC_DATE_ISO_FORMAT)?.format(MISC_DATE_POLISH_DATE_ONLY_FORMAT) } label="Data utworzenia" disabled/>
                    </Grid>
                    <Grid item>
                        <TextField fullWidth value={ getWarehouseDocument?.data?.deliveryDate ?
                            moment(getWarehouseDocument?.data?.deliveryDate, MISC_DATE_ISO_FORMAT)?.format(MISC_DATE_POLISH_DATE_ONLY_FORMAT) : '-' } label="Data dostawy" disabled/>
                    </Grid>
                </Grid>
                {
                    getWarehouseDocument?.data?.counterpartyName &&
                    <Grid item xs={ 8 }>
                        <TextField fullWidth value={ getWarehouseDocument?.data?.counterpartyName } label="Kontrahent" disabled/>
                    </Grid>
                }
                <Grid item xs={ 8 }>
                    <TextField fullWidth
                               value={ getAllWarehouses?.data?.map(warehouse => ({ value: warehouse?.internalId, label: getWarehouseName(warehouse) }))
                                   .find(warehouse => warehouse?.value === getWarehouseDocument?.data?.warehouseId)?.label }
                               label={ isMWDocument ? 'Magazyn źródłowy' : 'Magazyn' } disabled/>
                </Grid>
                {
                    isMWDocument &&
                    <Grid item xs={ 8 }>
                        <TextField fullWidth
                                   value={ getAllWarehouses?.data?.map(warehouse => ({ value: warehouse?.internalId, label: getWarehouseName(warehouse) }))
                                       .find(warehouse => warehouse?.value === getWarehouseDocument?.data?.targetWarehouseId)?.label || '' }
                                   label="Magazyn docelowy" disabled/>
                    </Grid>
                }
                <Grid container sx={ { paddingTop: '40px !important' } } item gap={ 2 }>
                    {
                        (showForm)
                            ? <DetailsForm
                                podId={ getAllWarehouses?.data?.find(warehouse => warehouse?.internalId === getWarehouseDocument?.data?.targetWarehouseId)?.pod?.podId }
                                queryParams={ location?.state?.queryParams }/>
                            : <DetailsList/>
                    }
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid mb={ 6 }>
            <Grid container justifyContent="space-between" rowGap={ 2 }>
                <Grid item>
                    <Button onClick={ () => navigate(ROUTE_WAREHOUSE_DOCUMENTS, { state: { queryParams: location?.state?.queryParams } }) }>
                        Wróć
                    </Button>
                </Grid>
                <Grid justifyContent="flex-end">
                    {
                        <Button
                            isLoading={ generateWarehouseDocumentLabels?.loading }
                            onClick={ onGenerateWarehouseDocumentLabels }>
                            Pobierz naklejki
                        </Button>
                    }
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );

};

export default WarehouseDocumentDetailsPage;