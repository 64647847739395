import { isEmptyArray, isEmptyObject, isNullOrUndefined } from './misc_utils';

export const extractResponseData = (response) => {
    if (isNullOrUndefined(response) || isNullOrUndefined(response.data)) {
        return null;
    }

    if (response.data.data === undefined) {
        return response.data;
    }

    return response.data.data;
};

export const extractErrorData = (error) => {
    if (isNullOrUndefined(error)) {
        return null;
    }

    if (isNullOrUndefined(error.response)) {
        return null;
    }

    if (isNullOrUndefined(error.response.data)) {
        return null;
    }

    return error.response.data;
};

export const extractErrorDataFromFile = async (file) => {
    return JSON.parse(await new Blob([file]).text());
};

export const exportFile = (data, fileName) => {
    // explained:
    // https://medium.com/@drevets/you-cant-prompt-a-file-download-with-the-content-disposition-header-using-axios-xhr-sorry-56577aa706d6

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
};

export const exportBase64File = (data, fileName, extension) => {
    const link = document.createElement('a');

    link.href = data;
    link.setAttribute('download', extension ? fileName.concat('.', extension) : fileName.concat('.', 'png'));
    link.click();
};

export const getDefaultPaginationQueryValues = {
    page: 0,
    size: 30,
    sort: 'name,asc'
};

export const getDefaultPaginationByIdQueryValues = {
    page: 0,
    size: 30,
    sort: 'id,asc'
};

export const getDefaultPodsPaginationQueryValues = {
    page: 0,
    size: 30,
    sort: 'indoorPartner.name,name,asc'
};

export const getDefaultEmployeePaginationQueryValues = {
    page: 0,
    size: 30,
    sort: 'email,asc'
};

export const getDefaultCustomerActivitiesPaginationQueryValues = {
    page: 0,
    size: 30,
    sort: 'date,desc'
};

export const getDefaultTheftRegistriesPaginationQueryValues = {
    page: 0,
    size: 30,
    sort: 'theftDate,desc'
};

export const getDefaultGenerateWarehouseDocumentRequestsPaginationQueryValues = {
    page: 0,
    size: 10,
    sort: 'deliveryDate,desc&sort=createDate,desc'
};

export const getDefaultFoodPartnerOrderHistoryPaginationQueryValues = {
    page: 0,
    size: 10,
    sort: 'orderDate,desc&sort=createDate,desc'
};

export const buildQueryString = (queryParams) => {
    let queryString = '';
    if (isNullOrUndefined(queryParams) || isEmptyObject(queryParams)) {
        return queryString;
    }

    let firstQueryParameter = true;

    for (let property in queryParams) {
        if (queryParams.hasOwnProperty(property)) {

            if (firstQueryParameter) {
                queryString += '?';
                firstQueryParameter = false;
            } else {
                queryString += '&';
            }

            queryString += property + '=' + queryParams[property];
        }
    }

    return queryString;
};

export const buildQueryParamValueArray = (array) => {
    let string = '';
    if (isNullOrUndefined(array) || isEmptyArray(array)) {
        return string;
    }

    for (let i = 0; i < array.length; i++) {
        if (i === array.length - 1) {
            string += array[i];
        } else {
            string += array[i] + ',';
        }
    }

    return string;
};
