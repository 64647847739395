import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';
import _ from 'lodash';

import { GenerateWarehouseDocumentTypeRequestEnum } from '../../../../../core/enums/inventory/warehouse_document';
import SummaryButtons from './summary_buttons';
import { createWarehouseDocumentAction, createWarehouseDocumentState } from '../../../../../features/inventory/create_warehouse_document';
import { createAndSendWarehouseDocumentAction, createAndSendWarehouseDocumentState } from '../../../../../features/inventory/create_and_send_warehouse_document';
import useNotification from '../../../../../core/hooks/use_notification';
import { getFoodPartnerState } from '../../../../../features/food_partner/get_food_partner';
import ThreeLevelSteps from '../../../common/steps';
import SummaryList from '../../../common/warehouse_and_food_partner_order_form/summary_list';
import { createWarehouseDocumentNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { ROUTE_WAREHOUSE_DOCUMENTS } from '../../../../../core/constants';

const Summary = ({ setView, data }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showNotification } = useNotification();

    const createWarehouseDocument = useSelector(createWarehouseDocumentState);
    const createAndSendWarehouseDocument = useSelector(createAndSendWarehouseDocumentState);
    const getFoodPartner = useSelector(getFoodPartnerState);

    const onReturn = () => {
        if (data?.form?.type === GenerateWarehouseDocumentTypeRequestEnum.PZ_DOCUMENT.value) {
            setView(1);
        }
        if (data?.form?.type === GenerateWarehouseDocumentTypeRequestEnum.PZ_AND_MM_DOCUMENTS.value) {
            setView(2);
        }
        if (data?.form?.type === GenerateWarehouseDocumentTypeRequestEnum.MM_DOCUMENTS.value) {
            setView(3);
        }
        if (data?.form?.type === GenerateWarehouseDocumentTypeRequestEnum.PW_DOCUMENT.value) {
            setView(4);
        }
    };

    const onSave = (generateLabels) => {
        onSubmit(createWarehouseDocumentAction, { ...data?.form, generateLabels });
    };

    const onSaveAndSend = (generateLabels) => {
        const form = { ..._.omit(data?.form, ['attachments']), generateLabels };
        const attachments = data?.form?.attachments;

        onSubmit(createAndSendWarehouseDocumentAction, { form, attachments });
    };

    const onSubmit = (action, form) => {
        dispatch(action(form))
            .then(response => {
                showNotification(createWarehouseDocumentNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && navigate(ROUTE_WAREHOUSE_DOCUMENTS);
            });
    };

    const getSummaryList = () => {
        const documentType = data?.form?.type;
        const allProducts = data?.formik?.productsList.flat();
        const types = [...new Set(allProducts?.map(product => product?.type))];
        const productsByType = types?.map(type => [...allProducts?.filter(product => product?.type === type)]);
        const products = productsByType?.map((products, index) => {
            return [...new Set(productsByType[index]?.map(product => product?.name))]?.map(productName => {
                const productQuantity = products?.filter(product => product?.name === productName)
                    ?.map(product => product?.quantity)
                    ?.reduce((accumulator, current) => accumulator + current, 0);

                return {
                    name: productName,
                    quantity: productQuantity
                };
            });
        });
        const destinationProducts = types?.map(type => {
            return data?.formik?.destinationsList?.map((_, index) => {
                const productsByType = data?.formik?.productsList[index]?.filter(product => product?.type === type);

                return [...new Set(productsByType?.map(product => product?.name))]?.map(productName => {
                    const productQuantity = productsByType?.filter(product => product?.name === productName)
                        ?.map(product => product?.quantity)
                        ?.reduce((accumulator, current) => accumulator + current, 0);

                    return {
                        name: productName,
                        quantity: productQuantity
                    };
                });
            });
        });

        return (
            <SummaryList
                showDestinationProductsModal={ ![GenerateWarehouseDocumentTypeRequestEnum.PZ_DOCUMENT.value, GenerateWarehouseDocumentTypeRequestEnum.PW_DOCUMENT.value].includes(documentType) }
                types={ types }
                productsByType={ productsByType }
                products={ products }
                destinationsList={ data?.formik?.destinationsList }
                destinationProducts={ destinationProducts }
            />
        );
    };

    const getButtons = () => {
        if (data?.form?.type === GenerateWarehouseDocumentTypeRequestEnum.MM_DOCUMENTS.value) {
            return (
                <SummaryButtons
                    onSave={ () => onSave(false) }
                    displaySaveAndSendAction={ false }
                    withoutGenerateLabels
                    onReturn={ onReturn }
                    isLoading={ createWarehouseDocument?.loading }/>
            );
        }

        if (data?.form?.type === GenerateWarehouseDocumentTypeRequestEnum.PW_DOCUMENT.value) {
            return (
                <SummaryButtons
                    onSave={ () => onSave(false) }
                    displaySaveAndSendAction={ false }
                    onReturn={ onReturn }
                    isLoading={ createWarehouseDocument?.loading }/>
            );
        }

        return (
            <SummaryButtons
                onSave={ (generateLabels) => onSave(generateLabels) }
                onSaveAndSend={ (generateLabels) => onSaveAndSend(generateLabels) }
                displaySaveAndSendAction={ getFoodPartner?.data?.containsContactPersons }
                onReturn={ onReturn }
                isLoading={ createWarehouseDocument?.loading || createAndSendWarehouseDocument?.loading }/>
        );
    };

    return (
        <Grid container rowGap={ 2 } paddingTop={ 2 }>
            <ThreeLevelSteps thirdStep/>
            <Grid container>
                <Typography variant="h4">
                    Podsumowanie
                </Typography>
            </Grid>
            <Grid container rowGap={ 2 }>
                {
                    getSummaryList()
                }
            </Grid>
            {
                getButtons()
            }
        </Grid>
    );
};

export default Summary;
