import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { INCORRECT_EMAILS_PATH } from '../../core/constants';
import { buildQueryString, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const createIncorrectEmailAction = createAsyncThunk(
    'customer/createIncorrectEmailAction',
    async (queryParams, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.post(`${ INCORRECT_EMAILS_PATH }${ buildQueryString(queryParams) }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const createIncorrectEmailSlice = createSlice({
    name: 'createIncorrectEmailSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(createIncorrectEmailAction.pending, () => STATE__API__STARTED);
        builder.addCase(createIncorrectEmailAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(createIncorrectEmailAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const createIncorrectEmailState = (state) => state.customer.createIncorrectEmail;
export default createIncorrectEmailSlice.reducer;