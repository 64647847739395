import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { GET_ROUTE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING_FORMS } from '../../../../../core/constants';
import { SubmitButton } from '../../../../common/button';
import { getAutomaticExpirationPromotionSettingsNotificationData, updateAutomaticExpirationPromotionSettingStatusActionNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import useNotification from '../../../../../core/hooks/use_notification';
import { getAutomaticExpirationPromotionSettingsAction, getAutomaticExpirationPromotionSettingsState } from '../../../../../features/price/get_automatic_expiration_promotion_settings';
import { getAutomaticExpirationPromotionSettingType } from '../../../../common/utils/automatic_expiration_promotion_setting_utils';
import { updateAutomaticExpirationPromotionSettingStatusAction, updateAutomaticExpirationPromotionSettingStatusState } from '../../../../../features/price/update_automatic_expiration_promotion_setting_status';

const AutomaticExpirationPromotionSettingList = ({ tab }) => {
    const { showErrorMessage, showNotification } = useNotification();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAutomaticExpirationPromotionSettings = useSelector(getAutomaticExpirationPromotionSettingsState);
    const updateAutomaticExpirationPromotionSettingStatus = useSelector(updateAutomaticExpirationPromotionSettingStatusState);

    const refreshAutomaticExpirationPromotionSettingList = useCallback(() => {
        dispatch(getAutomaticExpirationPromotionSettingsAction())
            .then(response => showErrorMessage(getAutomaticExpirationPromotionSettingsNotificationData(response)));
    }, [dispatch, showErrorMessage]);

    const onUpdateAutomaticExpirationPromotionSettingStatus = (type) => {
        dispatch(updateAutomaticExpirationPromotionSettingStatusAction(type))
            .then(response => {
                showNotification(updateAutomaticExpirationPromotionSettingStatusActionNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshAutomaticExpirationPromotionSettingList();
            });
    };

    useEffect(() => {
        refreshAutomaticExpirationPromotionSettingList();
    }, [refreshAutomaticExpirationPromotionSettingList]);


    return (
        <>
            {
                getAutomaticExpirationPromotionSettings?.loading &&
                <LinearProgress/>
            }
            {
                getAutomaticExpirationPromotionSettings?.data &&
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Typ promocji</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Zmiana statusu</TableCell>
                                <TableCell align="center">Edycja</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                getAutomaticExpirationPromotionSettings?.data?.map(automaticExpirationPromotionSetting => (
                                    <TableRow
                                        key={ automaticExpirationPromotionSetting?.type }
                                        hover
                                    >
                                        <TableCell>
                                            {
                                                getAutomaticExpirationPromotionSettingType(automaticExpirationPromotionSetting?.type)
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {
                                                automaticExpirationPromotionSetting?.active
                                                    ? 'Aktywny'
                                                    : 'Nieaktywny'
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            <SubmitButton onClick={ () => onUpdateAutomaticExpirationPromotionSettingStatus(automaticExpirationPromotionSetting?.type) } isLoading={ updateAutomaticExpirationPromotionSettingStatus?.loading }>
                                                {
                                                    automaticExpirationPromotionSetting?.active
                                                        ? 'Dezaktywuj'
                                                        : 'Aktywuj'
                                                }
                                            </SubmitButton>
                                        </TableCell>
                                        <TableCell align="center">
                                            <EditIcon onClick={ () => navigate(GET_ROUTE_AUTOMATIC_EXPIRATION_PROMOTION_SETTING_FORMS(automaticExpirationPromotionSetting?.type), { state: { tab } }) } cursor="pointer"/>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    );
};

export default AutomaticExpirationPromotionSettingList;
