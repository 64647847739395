import { deepFreeze } from '../../utils/misc_utils';

export const CustomerStatusEnum = deepFreeze({
    TO_ACTIVATE: {
        value: 'TO_ACTIVATE',
        label: 'Gotowy do aktywacji'
    },
    ACTIVE: {
        value: 'ACTIVE',
        label: 'Aktywny'
    },
    INACTIVE: {
        value: 'INACTIVE',
        label: 'Nieaktywny'
    },
    TO_REMOVE: {
        value: 'TO_REMOVE',
        label: 'Gotowy do usunięcia'
    },
    REMOVED: {
        value: 'REMOVED',
        label: 'Usunięty'
    },
    BLOCKED: {
        value: 'BLOCKED',
        label: 'Zablokowany'
    },
    UNKNOWN: {
        value: 'UNKNOWN',
        label: 'Błąd'
    }
});

export const CustomerFilterEnum = deepFreeze({
    ID: {
        value: 'ID',
        label: 'ID'
    },
    SURNAME: {
        value: 'SURNAME',
        label: 'Nazwisko'
    },
    PHONE_NUMBER: {
        value: 'PHONE_NUMBER',
        label: 'Numer telefonu'
    },
    EMAIL: {
        value: 'EMAIL',
        label: 'Email'
    }
});
