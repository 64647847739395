import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { DELIVERIES_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const updateAndConfirmDeliveryDocumentAction = createAsyncThunk(
    'inventory/updateAndConfirmDeliveryDocumentAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.patch(`${ DELIVERIES_PATH }/${ data.id }?podId=${ data.podId }`, data.form);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const updateAndConfirmDeliveryDocumentSlice = createSlice({
    name: 'updateAndConfirmDeliveryDocumentSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updateAndConfirmDeliveryDocumentAction.pending, () => STATE__API__STARTED);
        builder.addCase(updateAndConfirmDeliveryDocumentAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(updateAndConfirmDeliveryDocumentAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const updateAndConfirmDeliveryDocumentState = (state) => state.inventory.updateAndConfirmDeliveryDocument;
export default updateAndConfirmDeliveryDocumentSlice.reducer;
