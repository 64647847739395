import { deepFreeze } from '../../utils/misc_utils';

export const OrderSuccessMessageEnum = deepFreeze({
    CREATE_THEFT_REGISTRY: 'Pomyślnie utworzono kartotekę',
    UPDATE_THEFT_REGISTRY_STATUS: 'Pomyślnie zaktualizowano status kartoteki',
});

export const OrderErrorMessageEnum = deepFreeze({
    GET_CART_EVENTS: 'Pobranie zdarzeń koszyka nie powiodło się',
    GET_THEFT_REGISTRIES: 'Pobranie kartotek nie powiodło się',
    GET_THEFT_REGISTRIES_FOR_STORE: 'Pobranie kartotek nie powiodło się',
    GET_THEFT_REGISTRY: 'Pobranie kartoteki nie powiodło się',
    GET_POD_ORDERS: 'Pobranie zamówień nie powiodło się',
    CREATE_THEFT_REGISTRY: 'Utworzenie kartoteki nie powiodło się',
    UPDATE_THEFT_REGISTRY_STATUS: 'Aktualizacja statusu kartoteki nie powiodła się',
});

const OrderServerErrorMessageEnum = deepFreeze({});

export const getOrderErrorMessage = (message, defaultErrorMessage) => {
    for (let key in OrderServerErrorMessageEnum) {
        if (OrderServerErrorMessageEnum.hasOwnProperty(key)) {
            if (message?.substring(0, message?.indexOf(' ')) === OrderServerErrorMessageEnum[key].errorCode) {
                return OrderServerErrorMessageEnum[key].message;
            }
        }
    }

    return defaultErrorMessage;
};
