import { IncorrectEmailStatusEnum } from '../../../core/enums/customer/incorrect_email';
import { FoodPartnerOrderStatusEnum } from '../../../core/enums/inventory/food_partner_order';

export const getIncorrectEmailStatusOptions = [
    { value: IncorrectEmailStatusEnum.CREATED.value, label: IncorrectEmailStatusEnum.CREATED.label },
    { value: IncorrectEmailStatusEnum.UPDATED_EMAIL.value, label: IncorrectEmailStatusEnum.UPDATED_EMAIL.label }
];

export const getIncorrectEmailStatus = (status) => {
    switch (status) {
        case IncorrectEmailStatusEnum.CREATED.value:
            return IncorrectEmailStatusEnum.CREATED.label;
        case IncorrectEmailStatusEnum.UPDATED_EMAIL.value:
            return IncorrectEmailStatusEnum.UPDATED_EMAIL.label;
        default:
            return FoodPartnerOrderStatusEnum.UNKNOWN.label;
    }
};
