import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import deepmerge from 'deepmerge';

import useNotification from '../../../core/hooks/use_notification';
import { getPodsNotificationData, getTheftRegistriesForStoreNotificationData } from '../../../core/utils/notification_utils';
import { getDefaultPaginationByIdQueryValues } from '../../../core/utils/api_utils';
import PaginationControls from '../../common/pagination_controls';
import TheftRegistriesList from './list';
import TheftRegistriesFilters from './filters';
import { getTheftRegistriesForStoreAction, getTheftRegistriesForStoreState } from '../../../features/order/get_theft_registries_for_store';
import { getAllPodsAction, getAllPodsState } from '../../../features/pod/get_all_pods';

const initialQueryParams = {
    ...getDefaultPaginationByIdQueryValues,
    status: 'IN_REALIZATION',
    invoiceNumber: ''
};

const TheftRegistriesPage = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const location = useLocation();

    const getTheftRegistriesForStore = useSelector(getTheftRegistriesForStoreState);
    const getAllPods = useSelector(getAllPodsState);

    const [queryParams, setQueryParams] = useState(location?.state?.queryParams || initialQueryParams);

    const refreshList = useCallback(() => {
        dispatch(getTheftRegistriesForStoreAction(queryParams))
            .then(response => showErrorMessage(getTheftRegistriesForStoreNotificationData(response)));
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [refreshList]);

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } rowGap={ 4 } justifyContent="space-between">
                <Grid container>
                    <Typography variant="h1">Kradzieże</Typography>
                </Grid>
                <Grid container>
                    <TheftRegistriesFilters
                        queryParams={ queryParams }
                        setQueryParams={ setQueryParams }/>
                </Grid>
            </Grid>
            {
                (getTheftRegistriesForStore?.loading || getAllPods?.loading) &&
                <LinearProgress/>
            }
            {
                (getTheftRegistriesForStore?.data?.content && getAllPods?.data) &&
                <>
                    <TheftRegistriesList queryParams={queryParams} refreshList={ refreshList }/>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getTheftRegistriesForStore?.data?.pageable?.pageNumber }
                            totalPages={ getTheftRegistriesForStore?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </>
            }
        </Grid>
    );
};

export default TheftRegistriesPage;
