import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import moment from 'moment/moment';
import { FieldArray, Form, Formik } from 'formik';
import { Divider, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { getPodOrdersNotificationData, getPodsNotificationData, getTheftRegistryNotificationData } from '../../../../../../core/utils/notification_utils';
import useNotification from '../../../../../../core/hooks/use_notification';
import { getTheftRegistryAction, getTheftRegistryState } from '../../../../../../features/order/get_theft_registry';
import { SelectFieldFormik } from '../../../../../common/select_field';
import { DateTimePickerFormik } from '../../../../../common/date_picker';
import { getAllPodsAction, getAllPodsState } from '../../../../../../features/pod/get_all_pods';
import { yesNoOptions } from '../../../../../common/form_utils';
import RadioGroupFormik from '../../../../../common/radio_group';
import { GET_ROUTE_CUSTOMER, MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT, ROUTE_THEFT_REGISTRIES } from '../../../../../../core/constants';
import { getPodOrdersAction, getPodOrdersState } from '../../../../../../features/order/get_pod_orders';
import { Button } from '../../../../../common/button';
import TextFieldFormik from '../../../../../common/text_field';
import DetailsDialog from '../common/details_dialog';
import { isEmptyArray } from '../../../../../../core/utils/misc_utils';

const TheftRegistryDetailsPage = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const { customerId } = useParams();
    const { theftRegistryId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const getTheftRegistry = useSelector(getTheftRegistryState);
    const getAllPods = useSelector(getAllPodsState);
    const getPodOrders = useSelector(getPodOrdersState);

    const initialValues = {
        podId: getTheftRegistry?.data?.podId || '',
        theftDate: moment(getTheftRegistry?.data?.theftDate) || '',
        partialTheft: getTheftRegistry?.data?.partialTheft || false,
        incompleteOrderId: getTheftRegistry?.data?.incompleteOrderId || '',
        positions: getTheftRegistry?.data?.positions || []
    };

    useEffect(() => {
        dispatch(getTheftRegistryAction(theftRegistryId))
            .then(response => showErrorMessage(getTheftRegistryNotificationData(response)));
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
        dispatch(getPodOrdersAction({ orderDate: moment(location?.state?.theftDate, MISC_DATE_ISO_FORMAT)?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT), customerId, podId: location?.state?.podId }))
            .then(response => showErrorMessage(getPodOrdersNotificationData(response)));
    }, [customerId, dispatch, location?.state?.podId, location?.state?.theftDate, showErrorMessage, theftRegistryId]);

    const onReturnClick = () => {
        if (location?.state?.source === 'TheftRegistriesList') {
            navigate(ROUTE_THEFT_REGISTRIES, { state: { queryParams: location?.state?.queryParams } });
        } else {
            navigate(GET_ROUTE_CUSTOMER(customerId), { state: { tab: location?.state?.tab } });
        }
    };

    return (
        <Grid container mb={ 6 } pl={ 5 } pr={ 5 }>
            {
                (getTheftRegistry?.loading || getAllPods?.loading || getPodOrders?.loading) &&
                <Grid item width={ '100%' }>
                    <LinearProgress/>
                </Grid>
            }
            {
                (getTheftRegistry?.data && getAllPods?.data && getPodOrders?.data) &&
                <Formik
                    initialValues={ initialValues }
                    validateOnChange={ false }
                    validateOnBlur={ false }>
                    { (formik) =>
                        <Grid component={ Form } container rowGap={ 2 } paddingTop={ 2 }>
                            <Grid container item rowGap={ 2 } mb={ 2 }>
                                <Grid container item gap={ 6 }>
                                    <Grid item>
                                        <DateTimePickerFormik
                                            name="theftDate"
                                            label="Data kradzieży"
                                            required
                                            InputLabelProps={ { shrink: true } }
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item>
                                        <RadioGroupFormik
                                            name="partialTheft"
                                            label="Częściowa kradzież"
                                            options={ yesNoOptions }
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item>
                                    <Grid item>
                                        <SelectFieldFormik
                                            name="podId"
                                            label="Pod"
                                            options={ getAllPods?.data?.map(pod => ({ value: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') })) }
                                            required
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    !isEmptyArray(getPodOrders?.data) &&
                                    <Grid container item>
                                        <Grid item>
                                            <SelectFieldFormik
                                                name="incompleteOrderId"
                                                label="Zamówienie powiązane z kradzieżą"
                                                options={ getPodOrders?.data?.map(podOrder => ({
                                                    value: podOrder?.id,
                                                    label: 'Data zamówienia: ' + moment(podOrder?.createdDate, MISC_DATE_ISO_FORMAT).format(MISC_DATE_POLISH_FORMAT)
                                                        + ', wartość: ' + podOrder?.totalPrice + ' ' + podOrder?.currency
                                                        + ', ilość produktów: ' + podOrder?.numberOfProducts
                                                })) }
                                                required
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            <Grid container item mb={ 2 }>
                                <Divider style={ { width: '100%' } }/>
                            </Grid>
                            <FieldArray
                                name={ 'positions' }
                                render={ () => (
                                    <Grid container gap={ 2 }>
                                        <TableContainer>
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={ { width: '5%' } }>Lp.</TableCell>
                                                        <TableCell>Nazwa</TableCell>
                                                        <TableCell align="center" style={ { width: '10%' } }>Ilość szt.</TableCell>
                                                        <TableCell align="center" style={ { width: '8%' } }>Szczegóły pozycji</TableCell>
                                                        <TableCell align="center" style={ { width: '12%' } }>Cena sprzedaży</TableCell>
                                                        <TableCell align="center" style={ { width: '12%' } }>Cena ostateczna</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        formik?.values?.positions?.map((product, index) => (
                                                            <TableRow
                                                                key={ index }
                                                                hover
                                                            >
                                                                <TableCell>{ index + 1 }</TableCell>
                                                                <TableCell>
                                                                    <TextFieldFormik
                                                                        name={ `positions.${ index }.productName` }
                                                                        disabled/>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <TextFieldFormik
                                                                        name={ `positions.${ index }.quantity` }
                                                                        type="number"
                                                                        inputProps={ { min: '1', step: '1' } }
                                                                        style={ { background: 'white' } }
                                                                        disabled/>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <DetailsDialog product={ product }/>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <TextFieldFormik
                                                                        name={ `positions.${ index }.sellingPrice` }
                                                                        type="number"
                                                                        inputProps={ { min: '0.01', max: '100.00', step: '0.01' } }
                                                                        style={ { background: 'white' } }
                                                                        disabled/>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <TextFieldFormik
                                                                        name={ `positions.${ index }.shopAdminSellingPrice` }
                                                                        type="number"
                                                                        inputProps={ { min: '0.01', max: '100.00', step: '0.01' } }
                                                                        style={ { background: 'white' } }
                                                                        disabled/>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                ) }
                            />
                            <Grid item>
                                <Button onClick={ onReturnClick }>
                                    Wróć
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Formik>
            }
        </Grid>
    );
};

export default TheftRegistryDetailsPage;
