import { combineReducers } from '@reduxjs/toolkit';

import authorization from './authorization';
import product from './product';
import inventory from './inventory';
import pod from './pod';
import price from './price';
import customer from './customer';
import communication from './communication';
import store from './store';
import foodPartner from './food_partner';
import benefit from './benefit';
import reservation from './reservation';
import coupon from './coupon';
import order from './order';

const rootReducer = combineReducers({
    authorization,
    product,
    inventory,
    pod,
    price,
    customer,
    communication,
    store,
    foodPartner,
    benefit,
    reservation,
    coupon,
    order
});

export default rootReducer;
