import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import { FoodPartnerOrderStatusEnum } from '../../../../../../core/enums/inventory/food_partner_order';
import { SubmitButton } from '../../../../../common/button';
import { updateFoodPartnerOrderStatusAction, updateFoodPartnerOrderStatusState } from '../../../../../../features/inventory/update_food_partner_order_status';
import { getFoodPartnerOrderNotificationData, updateFoodPartnerOrderStatusNotificationData } from '../../../../../../core/utils/notification_utils';
import useNotification from '../../../../../../core/hooks/use_notification';
import { ApiRequestStatusEnum } from '../../../../../../core/enums/common/api';
import { getFoodPartnerOrderAction } from '../../../../../../features/inventory/get_food_partner_order';

const UpdateStatusButtons = ({ foodPartnerOrderStatus, foodPartnerOrderId }) => {
    const dispatch = useDispatch();
    const { showErrorMessage, showNotification } = useNotification();

    const updateFoodPartnerOrderStatus = useSelector(updateFoodPartnerOrderStatusState);

    const onUpdateFoodPartnerOrderStatus = (foodPartnerOrderNewStatus) => {
        dispatch(updateFoodPartnerOrderStatusAction({ id: foodPartnerOrderId, status: foodPartnerOrderNewStatus }))
            .then(response => {
                showNotification(updateFoodPartnerOrderStatusNotificationData(response));

                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    dispatch(getFoodPartnerOrderAction(foodPartnerOrderId))
                        .then(response => showErrorMessage(getFoodPartnerOrderNotificationData(response)));
                }
            });
    };

    return (
        <Grid container mb={ 2 } gap={ 2 } justifyContent="flex-end">
            {
                foodPartnerOrderStatus === FoodPartnerOrderStatusEnum.CREATED.value &&
                <Grid item>
                    <Grid container gap={ 2 }>
                        <SubmitButton onClick={ () => onUpdateFoodPartnerOrderStatus(FoodPartnerOrderStatusEnum.IN_PROGRESS.value) } isLoading={ updateFoodPartnerOrderStatus?.loading }>
                            Zmień status na "w realizacji"
                        </SubmitButton>
                        <SubmitButton onClick={ () => onUpdateFoodPartnerOrderStatus(FoodPartnerOrderStatusEnum.DELIVERED.value) } isLoading={ updateFoodPartnerOrderStatus?.loading }>
                            Zmień status na "dostarczone"
                        </SubmitButton>
                    </Grid>
                </Grid>
            }
            {
                foodPartnerOrderStatus === FoodPartnerOrderStatusEnum.IN_PROGRESS.value &&
                <Grid item>
                    <Grid container gap={ 2 }>
                        <SubmitButton onClick={ () => onUpdateFoodPartnerOrderStatus(FoodPartnerOrderStatusEnum.DELIVERED.value) } isLoading={ updateFoodPartnerOrderStatus?.loading }>
                            Zmień status na "dostarczone"
                        </SubmitButton>
                    </Grid>
                </Grid>
            }
            {
                foodPartnerOrderStatus === FoodPartnerOrderStatusEnum.DELIVERED.value &&
                <Grid item>
                    <Grid container gap={ 2 }>
                        <SubmitButton onClick={ () => onUpdateFoodPartnerOrderStatus(FoodPartnerOrderStatusEnum.IN_PROGRESS.value) } isLoading={ updateFoodPartnerOrderStatus?.loading }>
                            Zmień status na "w realizacji"
                        </SubmitButton>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
};

export default UpdateStatusButtons;