import React from 'react';
import { useSelector } from 'react-redux';

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import moment from 'moment/moment';

import { MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_DATE_ONLY_FORMAT } from '../../../../../core/constants';
import { getWarehouseDocumentState } from '../../../../../features/inventory/get_warehouse_document';

const DetailsList = () => {
    const getWarehouseDocument = useSelector(getWarehouseDocumentState);

    return (
        <>
            <Grid container>
                <Typography variant="h2">Pozycje dokumentu</Typography>
            </Grid>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Produkt</TableCell>
                            <TableCell align="center">Ilość</TableCell>
                            <TableCell align="center">Partia</TableCell>
                            <TableCell align="center">Data ważności</TableCell>
                            <TableCell align="center">Cena netto</TableCell>
                            <TableCell align="center">Cena brutto</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            getWarehouseDocument?.data?.positions?.map(item => (
                                <TableRow
                                    key={ item?.warehouseBatchId }
                                    hover
                                >
                                    <TableCell>{ item?.product?.name }</TableCell>
                                    <TableCell align="right">{ item?.quantity }</TableCell>
                                    <TableCell align="center">{ item?.productBatchId }</TableCell>
                                    <TableCell align="center">
                                        {
                                            moment(item?.expiryDate, MISC_DATE_ISO_FORMAT)
                                                .format(MISC_DATE_POLISH_DATE_ONLY_FORMAT)
                                        }
                                    </TableCell>
                                    <TableCell align="right">{ item?.netPrice?.toFixed(2) }</TableCell>
                                    <TableCell align="right">{ item?.grossPrice?.toFixed(2) }</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default DetailsList;
