import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import _ from 'lodash';

import { TwoLevelSteps } from '../../../../common/steps';
import ProductsList from './products_list';
import AddProductsDialog from './add_products_dialog';
import { GET_ROUTE_CUSTOMER, MISC_DATE_ISO_FORMAT } from '../../../../../../core/constants';
import { createTheftRegistryNotificationData } from '../../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../../core/enums/common/api';
import useNotification from '../../../../../../core/hooks/use_notification';
import { createTheftRegistryAction, createTheftRegistryState } from '../../../../../../features/order/create_theft_registry';
import ConfirmDialog from '../../../../common/confirm_dialog';
import { Button } from '../../../../../common/button';

const ProductsListForm = ({ customerId, form, setView, inventoryWarehouseId }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();
    const navigate = useNavigate();
    const location = useLocation();

    const createTheftRegistry = useSelector(createTheftRegistryState);

    const initialValues = {
        positions: []
    };

    const onReturn = () => setView(0);

    const onSubmit = (formik) => {
        const errors = validate(formik.values);

        if (errors.positions.length ||
            errors.products.length) {
            formik.setErrors(errors);
            return;
        }

        const formToSend = {
            ..._.omit(form, ['theftDate']),
            theftDate: form?.theftDate?.format(MISC_DATE_ISO_FORMAT),
            customerId,
            positions: formik?.values?.positions?.map(product => (
                {
                    ..._.omit(product, ['expirationDate', 'name', 'type', 'position'])
                }
            ))
        };

        dispatch(createTheftRegistryAction(formToSend))
            .then(response => {
                showNotification(createTheftRegistryNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    navigate(GET_ROUTE_CUSTOMER(customerId), { state: { tab: location?.state?.tab } });
                }
            });
    };

    const validate = (values) => {
        const errors = {
            positions: [],
            products: [],
            quantityErrors: {}
        };

        if (values.positions.length === 0) {
            errors.positions = 'Wybierz minimum 1 produkt';
        } else {
            values.positions.forEach((item, itemIndex) => {
                if (!item?.quantity) {
                    errors.products[itemIndex] = {
                        ...errors.products[itemIndex],
                        quantity: 'Pole wymagane'
                    };
                } else {
                    if (item?.quantity <= 0) {
                        errors.products[itemIndex] = {
                            ...errors.products[itemIndex],
                            quantity: 'Proszę podać wartość całkowitą dodatnią'
                        };
                    }
                }

                if (!item?.shopAdminSellingPrice) {
                    errors.products[itemIndex] = {
                        ...errors.products[itemIndex],
                        shopAdminSellingPrice: 'Pole wymagane'
                    };
                } else {
                    if (item?.shopAdminSellingPrice <= 0) {
                        errors.products[itemIndex] = {
                            ...errors.products[itemIndex],
                            shopAdminSellingPrice: 'Proszę podać wartość dodatnią'
                        };
                    }
                }
            });

            if (errors.positions.length || errors.products.length) return errors;


            values.positions.forEach((product, productIndex) => {
                const productForValidation = values?.productsForValidation?.find(pfv => (pfv?.product?.id === product?.productId) && (pfv?.productBatchId === product?.productBatchId));

                if (errors.quantityErrors?.[product?.productBatchId]?.[product?.productId]) {
                    errors.products[productIndex] = {
                        ...errors.products[productIndex],
                        quantity: errors.quantityErrors?.[product?.productBatchId]?.[product?.productId]
                    };

                    return;
                }

                const productsSum = values.positions.flat()
                    .filter(p => (p?.productId === product?.productId) && (p?.productBatchId === product?.productBatchId))
                    .map(p => p.quantity)
                    .reduce((accumulator, current) => accumulator + current, 0);

                if (productForValidation.quantity < productsSum) {
                    errors.products[productIndex] = {
                        ...errors.products[productIndex],
                        quantity: `Dostępne ${ productForValidation.quantity } szt. na magazynie`
                    };

                    errors.quantityErrors[product?.productBatchId] = {
                        ...errors.quantityErrors[product?.productBatchId],
                        [product?.productId]: `Dostępne ${ productForValidation.quantity } szt. na magazynie`
                    };
                }
            });
        }

        return errors;
    };

    return (
        <Formik initialValues={ initialValues }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 2 } paddingTop={ 2 }>
                    <TwoLevelSteps secondStep/>
                    <Card sx={ { width: '100%', backgroundColor: '#f5f5f5' } }>
                        <CardContent>
                            <Typography variant="h4" mb={ 5 }>
                                Lista produktów
                            </Typography>
                            <ProductsList formik={ formik }/>
                            <AddProductsDialog
                                form={ form }
                                formik={ formik }
                                customerId={ customerId }
                                sourceWarehouseId={ inventoryWarehouseId }
                            />
                        </CardContent>
                    </Card>
                    <Grid container item mt={ 2 } justifyContent="space-between">
                        <Grid item>
                            <Button onClick={ onReturn }>Wróć</Button>
                        </Grid>
                        <Grid item>
                            <ConfirmDialog
                                isLoading={ createTheftRegistry?.loading }
                                title={ 'Czy na pewno chcesz utworzyć nowy wpis? Akcja zakończy się wysyłką faktury do klienta.' }
                                cancelText={ 'Anuluj' }
                                primaryAction={ () => onSubmit(formik) }
                                primaryText={ 'Utwórz' }
                                displayIcon={ false }
                                buttonText={ 'Utwórz i wyślij fakturę' }/>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Formik>
    );
};

export default ProductsListForm;
