import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { GET_ROUTE_CUSTOMER } from '../../../core/constants';
import { getCustomersState } from '../../../features/customer/get_customers';
import { getCustomerStatus } from '../../common/utils/customer_utils';

const CustomersList = ({queryParams}) => {
    const navigate = useNavigate();

    const getCustomers = useSelector(getCustomersState);

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="center">Imię</TableCell>
                        <TableCell align="center">Nazwisko</TableCell>
                        <TableCell align="center">Nr telefonu</TableCell>
                        <TableCell align="center">Email</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Podgląd</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getCustomers?.data?.content?.map(customer => (
                            <TableRow
                                key={ customer?.id }
                                hover
                            >
                                <TableCell>{ customer?.id }</TableCell>
                                <TableCell align="center">{ customer?.name }</TableCell>
                                <TableCell align="center">{ customer?.surname }</TableCell>
                                <TableCell align="center">{ customer?.phonePrefix?.prefix + customer?.phoneNumber }</TableCell>
                                <TableCell align="center">{ customer?.email }</TableCell>
                                <TableCell align="center">
                                    {
                                        getCustomerStatus(customer?.status)
                                    }
                                </TableCell>

                                <TableCell align="center">
                                    <SearchIcon onClick={ () => navigate(GET_ROUTE_CUSTOMER(customer?.id), { state: { queryParams }}) } cursor="pointer"/>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CustomersList;
