import { combineReducers } from '@reduxjs/toolkit';

import getCartEvents from './get_cart_events';
import getTheftRegistries from './get_theft_registries';
import getPodOrders from './get_pod_orders';
import createTheftRegistry from './create_theft_registry';
import updateTheftRegistryStatus from './update_theft_registry_status';
import getTheftRegistry from './get_theft_registry';
import getTheftRegistriesForStore from './get_theft_registries_for_store';

export default combineReducers({
    getCartEvents, getTheftRegistries, getPodOrders, createTheftRegistry, updateTheftRegistryStatus ,getTheftRegistry,
    getTheftRegistriesForStore
});
