import { deepFreeze } from '../../utils/misc_utils';

export const ReservationSuccessMessageEnum = deepFreeze({
    CREATE_PRODUCT_AVAILABILITY: 'Pomyślnie dodano produkt do kalendarza',
    IMPORT_PRODUCT_AVAILABILITY: 'Pomyślnie wczytano produkty do kalendarza',
    DELETE_PRODUCT_AVAILABILITY: 'Pomyślnie usunięto produkt z kalendarza',
    DELETE_RESERVATION: 'Pomyślnie usunięto rezerwację',
    UPDATE_PRODUCT_QUANTITY_LIMIT: 'Pomyślnie zmieniono limit',
    UPDATE_RESERVATION_STORE_STATUS: 'Pomyślnie zmieniono wewnętrzny status',
    UPDATE_POD_RESERVATION_CONFIG: 'Pomyślnie zmieniono konfigurację rezerwacji punktu sprzedaży'
});

export const ReservationErrorMessageEnum = deepFreeze({
    GET_POD_RESERVATION_CONFIGS: 'Pobranie konfiguracji rezerwacji punktów sprzedaży nie powiodło się',
    GET_PRODUCTS_AVAILABILITY: 'Pobranie kalendarza nie powiodło się',
    GET_PRODUCTS_AVAILABILITY_SINGLE_DAY: 'Pobranie danych ze wskazanego dnia nie powiodło się',
    CREATE_PRODUCT_AVAILABILITY: 'Dodanie produktu do kalendarze nie powiodło się',
    IMPORT_PRODUCT_AVAILABILITY: 'Wczytanie produktów do kalendarze nie powiodło się',
    DELETE_PRODUCT_AVAILABILITY: 'Usunięcie produktu z kalendarza nie powiodło się',
    GET_RESERVATIONS: 'Pobranie rezerwacji nie powiodło się',
    DELETE_RESERVATION: 'Usunięcie rezerwacji nie powiodło się',
    UPDATE_PRODUCT_QUANTITY_LIMIT: 'Zmiana limitu nie powiodła się',
    UPDATE_RESERVATION_STORE_STATUS: 'Zmiana wewnętrznego statusu nie powiodła się',
    UPDATE_POD_RESERVATION_CONFIG: 'Zmiana konfiguracji rezerwacji punktu sprzedaży nie powiodła się'
});

const ReservationServerErrorMessageEnum = deepFreeze({
    FOOD_PARTNER_NOT_SUPPORT_RESERVATION: {
        message: 'Dostawca z wyłączoną opcją rezerwacji',
        errorCode: '400.2'
    },
    EXISTS_CONNECTED_RESERVATION: {
        message: 'Wykryto rezerwację, skontaktuj się z klientem/klientami',
        errorCode: '400.3'
    },
    RESERVATION_IN_INVALID_STATE: {
        message: 'Nie można usunąć rezerwacji o danym statusie',
        errorCode: '400.4'
    },
    INVALID_GENERAL_QUANTITY_LIMIT: {
        message: 'Niepoprawny globalny limit',
        errorCode: '400.9'
    },
    INVALID_NEW_GENERAL_QUANTITY_LIMIT: {
        message: 'Nie można zmienić globalnego limitu, liczba rezerwacji przekracza nowy globalny limit',
        errorCode: '400.10'
    },
    INVALID_POD_QUANTITY_LIMIT: {
        message: 'Niepoprawny limit poda',
        errorCode: '400.15'
    },
    INVALID_NEW_POD_QUANTITY_LIMIT: {
        message: 'Nie można zmienić limitu poda, liczba rezerwacji przekracza nowy limit poda',
        errorCode: '400.16'
    },
    MINIMUM_ONE_PAYMENT_TYPE_IS_REQUIRED: {
        message: 'Przynajmniej jeden sposób płatności za rezerwacje musi zostać wskazany',
        errorCode: '400.21'
    }
});

export const getReservationErrorMessage = (message, defaultErrorMessage) => {
    for (let key in ReservationServerErrorMessageEnum) {
        if (ReservationServerErrorMessageEnum.hasOwnProperty(key)) {
            if (message?.substring(0, message?.indexOf(' ')) === ReservationServerErrorMessageEnum[key].errorCode) {
                return ReservationServerErrorMessageEnum[key].message;
            }
        }
    }

    return defaultErrorMessage;
};
