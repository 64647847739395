import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { Button, SubmitButton } from '../../../../../common/button';
import { SelectFieldFormik } from '../../../../../common/select_field';
import { DateTimePickerFormik } from '../../../../../common/date_picker';
import { parseDateString } from '../../../../../../core/utils/date_utils';
import { TwoLevelSteps } from '../../../../common/steps';
import { yesNoOptions } from '../../../../../common/form_utils';
import RadioGroupFormik from '../../../../../common/radio_group';
import { getAllPodsState } from '../../../../../../features/pod/get_all_pods';
import { getPodOrdersNotificationData } from '../../../../../../core/utils/notification_utils';
import { getPodOrdersAction, getPodOrdersState } from '../../../../../../features/order/get_pod_orders';
import useNotification from '../../../../../../core/hooks/use_notification';
import { GET_ROUTE_CUSTOMER, MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT } from '../../../../../../core/constants';

const GeneralDataForm = ({ customerId, form, onSubmitGeneralData }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const location = useLocation();
    const navigate = useNavigate();

    const getAllPods = useSelector(getAllPodsState);
    const getPodOrders = useSelector(getPodOrdersState);

    const initialValues = {
        podId: form?.podId || '',
        theftDate: form?.theftDate || '',
        partialTheft: form?.partialTheft || false,
        incompleteOrderId: form?.incompleteOrderId || ''
    };

    const schema = Yup.object().shape({
        podId: Yup.string().required(),
        theftDate: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required(),
        partialTheft: Yup.boolean().required(),
        incompleteOrderId: Yup.string().when('partialTheft', {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired()
        })
    });

    const downloadOrders = (partialTheft, theftDate, podId) => {
        if (partialTheft && theftDate && podId) {
            dispatch(getPodOrdersAction({ orderDate: theftDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT), customerId, podId }))
                .then(response => showErrorMessage(getPodOrdersNotificationData(response)));
        }
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmitGeneralData }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 2 } paddingTop={ 2 }>
                    <TwoLevelSteps firstStep/>
                    <Grid container item rowGap={ 2 } mb={ 2 }>
                        <Grid container item gap={ 6 }>
                            <Grid item>
                                <DateTimePickerFormik
                                    name="theftDate"
                                    label="Data kradzieży"
                                    required
                                    InputLabelProps={ { shrink: true } }
                                    onChange={ (theftDate) => downloadOrders(formik?.values?.partialTheft, theftDate, formik?.values?.podId) }
                                />
                            </Grid>
                            <Grid item>
                                <RadioGroupFormik
                                    name="partialTheft"
                                    label="Częściowa kradzież"
                                    options={ yesNoOptions }
                                    onChange={ (partialTheft) => downloadOrders(partialTheft, formik?.values?.theftDate, formik?.values?.podId) }
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 12 } lg={ 8 }>
                                <SelectFieldFormik
                                    name="podId"
                                    label="Pod"
                                    options={ getAllPods?.data?.map(pod => ({ value: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') })) }
                                    required
                                    onChange={ (podId) => downloadOrders(formik?.values?.partialTheft, formik?.values?.theftDate, podId) }
                                />
                            </Grid>
                        </Grid>
                        {
                            (formik.values.partialTheft && formik.values.theftDate && formik.values.podId) &&
                            <Grid container item>
                                <Grid item xs={ 12 } lg={ 8 }>
                                    <SelectFieldFormik
                                        name="incompleteOrderId"
                                        label="Zamówienie powiązane z kradzieżą"
                                        options={ getPodOrders?.data?.map(podOrder => ({
                                            value: podOrder?.id,
                                            label: 'Data zamówienia: ' + moment(podOrder?.createdDate, MISC_DATE_ISO_FORMAT).format(MISC_DATE_POLISH_FORMAT)
                                                + ', wartość: ' + podOrder?.totalPrice + ' ' + podOrder?.currency
                                                + ', ilość produktów: ' + podOrder?.numberOfProducts
                                        })) }
                                        required
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid container item mt={ 2 } justifyContent="space-between">
                        <Grid item>
                            <Button onClick={ () => navigate(GET_ROUTE_CUSTOMER(customerId), { state: { tab: location?.state?.tab } }) }>Wróć</Button>
                        </Grid>
                        <Grid item>
                            <SubmitButton>Dalej</SubmitButton>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Formik>
    );
};

export default GeneralDataForm;
