import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { CUSTOMER_PATH } from '../../core/constants';
import { buildQueryParamValueArray, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getCustomersByIdsAction = createAsyncThunk(
    'customer/getCustomersByIdsAction',
    async (customerIds, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ CUSTOMER_PATH }/customerIds?customerId=${ buildQueryParamValueArray(customerIds) }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getCustomersByIdsSlice = createSlice({
    name: 'getCustomersByIdsSlice',
    initialState: DEFAULT_STATE,
    reducers: {
        invalidateCustomersByIds() {
            return DEFAULT_STATE;
        }
    },
    extraReducers: builder => {
        builder.addCase(getCustomersByIdsAction.pending, () => STATE__API__STARTED);
        builder.addCase(getCustomersByIdsAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getCustomersByIdsAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const { invalidateCustomersByIds } = getCustomersByIdsSlice.actions;
export const getCustomersByIdsState = (state) => state.customer.getCustomersByIds;
export default getCustomersByIdsSlice.reducer;