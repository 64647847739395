import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment/moment';
import { Grid, LinearProgress, TextField } from '@mui/material';

import useNotification from '../../../../core/hooks/use_notification';
import { getCustomerAction, getCustomerState } from '../../../../features/customer/get_customer';
import { getCustomerNotificationData, updateCustomerStatusNotificationData } from '../../../../core/utils/notification_utils';
import { getButtonText, getCustomerStatus, getUpdateCustomerStatus } from '../../../common/utils/customer_utils';
import { MISC_DATE_POLISH_FORMAT } from '../../../../core/constants';
import { SubmitButton } from '../../../common/button';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { updateCustomerStatusAction, updateCustomerStatusState } from '../../../../features/customer/update_customer_status';

const CustomerDetails = ({ customerId }) => {
    const dispatch = useDispatch();
    const { showErrorMessage, showNotification } = useNotification();

    const getCustomer = useSelector(getCustomerState);
    const updateCustomerStatus = useSelector(updateCustomerStatusState);

    useEffect(() => {
        dispatch(getCustomerAction(customerId))
            .then(response => showErrorMessage(getCustomerNotificationData(response)));
    }, [dispatch, customerId, showErrorMessage]);

    const onUpdateCustomerStatus = (customer) => {
        dispatch(updateCustomerStatusAction({ customerId: customer?.id, status: getUpdateCustomerStatus(customer?.status) }))
            .then(response => {
                showNotification(updateCustomerStatusNotificationData(response));

                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    dispatch(getCustomerAction(customerId))
                        .then(response => showErrorMessage(getCustomerNotificationData(response)));
                }
            });
    };

    return (
        <Grid>
            {
                getCustomer?.loading &&
                <LinearProgress/>
            }
            {
                getCustomer?.data &&
                <Grid container>
                    <Grid container item mb={ 4 }>
                        <SubmitButton onClick={ () => onUpdateCustomerStatus(getCustomer?.data) } isLoading={ updateCustomerStatus?.loading || getCustomer?.loading }>
                            {
                                getButtonText(getCustomer?.data?.status)
                            }
                        </SubmitButton>
                    </Grid>
                    <Grid container item spacing={ 2 }>
                        <Grid container item md={ 12 } lg={ 5 } gap={ 2 } direction="column">
                            <Grid container item>
                                <TextField
                                    label="ID"
                                    value={ getCustomer?.data?.id }
                                    fullWidth
                                    InputLabelProps={ { shrink: true } }
                                    disabled/>
                            </Grid>
                            <Grid container item>
                                <TextField
                                    label="Imię"
                                    value={ getCustomer?.data?.name }
                                    fullWidth
                                    InputLabelProps={ { shrink: true } }
                                    disabled/>
                            </Grid>
                            <Grid container item>
                                <TextField
                                    label="Nazwisko"
                                    value={ getCustomer?.data?.surname }
                                    fullWidth
                                    InputLabelProps={ { shrink: true } }
                                    disabled/>
                            </Grid>
                            <Grid container item>
                                <TextField
                                    label="Numer telefonu"
                                    value={ getCustomer?.data?.phonePrefix?.prefix.concat(getCustomer?.data?.phoneNumber) }
                                    fullWidth
                                    InputLabelProps={ { shrink: true } }
                                    disabled/>
                            </Grid>
                            <Grid container item>
                                <TextField
                                    label="Email"
                                    value={ getCustomer?.data?.email }
                                    fullWidth
                                    InputLabelProps={ { shrink: true } }
                                    disabled/>
                            </Grid>
                        </Grid>
                        <Grid container md={ 12 } lg={ 7 } item gap={ 2 } direction="column">
                            <Grid container item>
                                <TextField
                                    label="Status"
                                    value={ getCustomerStatus(getCustomer?.data?.status) }
                                    fullWidth
                                    InputLabelProps={ { shrink: true } }
                                    disabled/>
                            </Grid>
                            <Grid container item>
                                <TextField
                                    label="Data utworzenia"
                                    value={ moment(getCustomer?.data?.createDate).format(MISC_DATE_POLISH_FORMAT) }
                                    fullWidth
                                    InputLabelProps={ { shrink: true } }
                                    disabled/>
                            </Grid>
                            <Grid container item>
                                <TextField
                                    label="Data ostatniej aktywności"
                                    value={ moment(getCustomer?.data?.lastActiveDate).format(MISC_DATE_POLISH_FORMAT) }
                                    fullWidth
                                    InputLabelProps={ { shrink: true } }
                                    disabled/>
                            </Grid>
                            <Grid container item>
                                <TextField
                                    label="Domyślny język"
                                    value={ getCustomer?.data?.defaultLanguageCode }
                                    fullWidth
                                    InputLabelProps={ { shrink: true } }
                                    disabled/>
                            </Grid>
                            <Grid container item>
                                <TextField
                                    label="Dane firmy"
                                    value={ 'Nazwa firmy: ' + (getCustomer?.data?.companyName || '- ') + ', NIP: ' + (getCustomer?.data?.companyNip || '-') }
                                    fullWidth
                                    InputLabelProps={ { shrink: true } }
                                    disabled/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
};

export default CustomerDetails;