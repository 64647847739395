import { deepFreeze } from '../../utils/misc_utils';

export const AccessTypeEnum = deepFreeze({
    PUBLIC: {
        value: 'PUBLIC',
        label: 'Publiczny'
    },
    PRIVATE: {
        value: 'PRIVATE',
        label: 'Prywatny'
    },
    HIDDEN: {
        value: 'HIDDEN',
        label: 'Ukryty'
    },
    UNKNOWN: {
        value: 'UNKNOWN',
        label: 'Błąd'
    }
});

export const PodPurposeEnum = deepFreeze({
    SALE: {
        value: 'SALE',
        label: 'Sprzedaż'
    },
    DELIVERY: {
        value: 'DELIVERY',
        label: 'Dostawa'
    },
    SALE_AND_DELIVERY: {
        value: 'SALE_AND_DELIVERY',
        label: 'Sprzedaż i dostawa'
    },
    UNKNOWN: {
        value: 'UNKNOWN',
        label: 'Błąd'
    }
});

export const LocationTypeEnum = deepFreeze({
    AIRPORT: {
        value: 'AIRPORT',
        label: 'Lotnisko'
    },
    HOSPITAL: {
        value: 'HOSPITAL',
        label: 'Szpital'
    },
    MANUFACTURE: {
        value: 'MANUFACTURE',
        label: 'Fabryka'
    },
    OFFICE: {
        value: 'OFFICE',
        label: 'Biuro'
    },
    SCHOOL: {
        value: 'SCHOOL',
        label: 'Szkoła'
    },
    UNIVERSITY: {
        value: 'UNIVERSITY',
        label: 'Uniwersytet'
    },
    UNKNOWN: {
        value: 'UNKNOWN',
        label: 'Błąd'
    }
});
