import { deepFreeze } from '../../utils/misc_utils';

export const CartEventActionEnum = deepFreeze({
    CREATE: {
        value: 'CREATE',
        label: 'Utwórz'
    },
    ADD_PRODUCT: {
        value: 'ADD_PRODUCT',
        label: 'Dodaj produkt'
    },
    ADD_PRODUCT_FAIL: {
        value: 'ADD_PRODUCT_FAIL',
        label: 'Błąd podczas dodawania produktu'
    },
    ADD_RESERVATION_PRODUCTS: {
        value: 'ADD_RESERVATION_PRODUCTS',
        label: 'Dodaj produkt do rezerwacji'
    },
    REMOVE_PRODUCT: {
        value: 'REMOVE_PRODUCT',
        label: 'Usuń produkt'
    },
    CHANGE_STATUS: {
        value: 'CHANGE_STATUS',
        label: 'Zmień status'
    },
    REFRESH: {
        value: 'REFRESH',
        label: 'Odśwież'
    },
    FINALIZE: {
        value: 'FINALIZE',
        label: 'Sfinalizuj'
    },
    CLEAR: {
        value: 'CLEAR',
        label: 'Wyczyść'
    }
});
